<template>
  <section class="section section-shaped section-lg my-0">
    <div class="shape shape-style-1 bg-gradient-success"></div>

    <section class="section section-lg pt-15">
      <div class="container">
        <card gradient="secondary" shadow body-classes="p-lg-5">
          <h4 class="text-success">Konfirmasi Passworsd Anda.</h4>
          <p class="mt-0">Masukan Password Baru anda.</p>

          <!-- Stack the columns on mobile by making one full-width and the other half-width -->
          <div class="row">
            <div class="col-12 col-md-12">
              <base-input
                alternative
                placeholder="Password Baru"
                addon-left-icon="ni ni-lock-circle-open"
              >
              </base-input>
            </div>
            <div class="col-12 col-md-12">
              <base-input
                alternative
                placeholder="Konfirmasi Password Baru"
                addon-left-icon="ni ni-lock-circle-open"
              >
              </base-input>
            </div>
            <div class="col-md-6">
              <ul class="nav justify-content-end">
                <base-button type="success" icon="ni ni-send">
                  Kirim
                </base-button>
              </ul>
            </div>
          </div>
        </card>
        <card gradient="secondary" shadow body-classes="p-lg-5">
          <h4 class="text-success">Reset Passworsd Anda.</h4>
          <p class="mt-0">Masukan Password Baru anda pada filed bawah ini.</p>
          <!-- Stack the columns on mobile by making one full-width and the other half-width -->
          <b-alert :show="show" variant="danger">
            <h4 class="alert-heading">Peringatan!</h4>
            <ul id="example-1">
              <li v-for="(item, index) in items" v-bind:key="index">
                {{ item[0] }}
              </li>
            </ul>
          </b-alert>
          <form v-on:submit.prevent="changepassword" role="form">
            <div class="row">
              <div class="col-12 col-md-10">
                <base-input
                  placeholder="Password Baru"
                  addon-left-icon="ni ni-lock-circle-open"
                  type="password"
                  v-model="password"
                  required
                >
                </base-input>
              </div>
              <div class="col-12 col-md-10">
                <base-input
                  placeholder="Konfirmasi Password Baru"
                  addon-left-icon="ni ni-lock-circle-open"
                  type="password"
                  v-model="password_c"
                  required
                >
                </base-input>
              </div>
              <div class="col-6 col-md-2">
                <base-button
                  nativeType="submit"
                  type="success"
                  icon="ni ni-bold-right"
                >
                  Kirim
                </base-button>
              </div>
            </div>
          </form>
        </card>
      </div>
    </section>
  </section>
</template>
<script>
import axios from "axios";
export default {
  name: "lupaspass",
  data() {
    return {
      password: null,
      password_c: null,
      items: [],
      show: false,
    };
  },
  methods: {
    changepassword: function () {
      var token = this.$router.history.current.params.token;
      let self = this;
      axios({
        method: "post",
        url: self.$config.url_api + "changepasswordbytoken",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: {
          token: token,
          password: this.password,
          password_confirmation: this.password_c,
        },
      }).then(function (response) {
        if (response.data.status == "ok") {
          self.show = false;
          alert("Password berhasil diubah, silahkan login kembali");
          self.$router.push({
            path: "/login",
          });
        } else {
          self.show = true;
          self.items = response.data.errors;
        }
      });
    },
  },
};
</script>
<style>
</style>
