<template>

    <section class="section section-shaped section-lg my-0">
        <div class="shape shape-style-1 bg-gradient-success">
        </div>
        <div class="container pt-lg-md">
            <div class="row justify-content-center">
                <div class="col-lg-5">
                    <card type="secondary" shadow
                          header-classes="bg-white pb-5"
                          body-classes="px-lg-5 py-lg-5"
                          class="border-0">
                          <template>
                            <form v-on:submit.prevent="register" role="form">
                                <h4 class="text-center"><strong>AKUN PEMOHON</strong></h4>
                                <hr>
                                <span class="text-left text-danger" style="font-size:0.8rem">Wajib diisi oleh pemohon izin</span>
                                <base-input alternative
                                            class="mb-3"
                                            placeholder="Nama Lengkap"
                                            addon-left-icon="ni ni-circle-08"
                                            v-model="nama_lengkap"
                                            >
                                </base-input>
                                <base-input alternative
                                            class="mb-3"
                                            type="number"
                                            placeholder="No Handphone"
                                            addon-left-icon="ni ni-mobile-button"
                                            v-model="nohp">
                                </base-input>
                                <div>
                                    <base-radio name="KTP" class="mb-3" v-model="jenis" value="KTP">
                                        KTP
                                    </base-radio>

                                    <base-radio name="Passport" class="mb-3" v-model="jenis" value="PASSPORT">
                                        Passport
                                    </base-radio>
                                </div>
                                <base-input alternative
                                            class="mb-3"
                                            type="number"
                                            placeholder="No. KTP/Passport"
                                            addon-left-icon="ni ni-badge"
                                            v-model="nojenis">
                                </base-input>
                                <div class="row mb-3">
                                    <div class="col">
                                        <textarea class="form-control form-control-alternative" rows="3" placeholder="Alamat Sesuai KTP/Passport" v-model="alamat">></textarea>
                                    </div>
                                </div>
                                <base-input alternative
                                            class="mb-3"
                                            type="email"
                                            placeholder="Email"
                                            addon-left-icon="ni ni-email-83"
                                            helpText="Harap menggunakan email aktif, segala notifikasi apapun akan dikirim ke email tersebut."
                                            v-model="email">
                                            
                                </base-input>
                                <base-input alternative
                                            type="password"
                                            placeholder="Password"
                                            addon-left-icon="ni ni-lock-circle-open"
                                            v-model="password"
                                            >
                                </base-input>
                                <!-- <base-checkbox>
                                    <span>Saya Setuju
                                        <a href="#">Privacy Policy</a>
                                    </span>
                                </base-checkbox> -->
                                <b-alert :show="show" variant="danger">
                                    <h4 class="alert-heading">Peringatan!</h4>
                                    <ul id="example-1">
                                        <li v-for="(item, index) in items" v-bind:key="index">
                                            {{ item[0] }}
                                        </li>
                                    </ul>
                                </b-alert>
                                <div class="text-center" v-if="!registered">
                                    <base-button nativeType="submit" type="success" class="my-4">Buat Akun</base-button>
                                </div>
                                <b-row class="mt-5" v-if="registered">
                                    <b-col md="4" offset-md="4">
                                        <double-bounce></double-bounce>
                                    </b-col>
                                </b-row>
                            </form>
                        </template>
                    </card>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios';
import {DoubleBounce} from 'vue-loading-spinner'
export default {
    components: {
      DoubleBounce
    },
    data(){
        return {
         nama_lengkap : '',
         nohp : '',
         nojenis : '',
         alamat : '',
         email : '',
         password : '',
         jenis: "",
         items: [],
         show: false,
         registered : false
       }
    },
    methods : {
        register : function () {
            let self = this;
            self.registered = true;
            axios({
              method: 'post',
              url: self.$config.url_api+'register',
              headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
              },
              data: {
                nama_lengkap: this.nama_lengkap,
                nohp: this.nohp,
                jenis: this.jenis,
                nojenis: this.nojenis,
                alamat: this.alamat,
                email: this.email,
                password: this.password
              }
            }).then(function (response) {
              if(response.data.success == true){
                alert("Silahkan klik link verifikasi di email anda sebelum login");
                self.show = false;
                self.registered = false;
                self.$router.push('login');
              }else{
                  self.registered = false;
                  self.show = true;
                  self.items = response.data.errors
              }
            });
            
        }
    }
};
</script>
<style>
</style>
