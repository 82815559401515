<template>
  <section class="section section-shaped section-lg my-0">
    <div class="shape shape-style-1 bg-gradient-success"></div>
    <div class="container pt-lg-md">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <card
            type="secondary"
            shadow
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0"
          >
            <template>
              <b-alert
                :show="dismissCountDown"
                dismissible
                :variant="variant"
                @dismissed="dismissCountDown = 0"
              >
                <p v-html="alertMessage"></p>
                <span v-if="error === 'Email not valid'">Kirim ulang kode verifikasi <b-button variant="link" @click="kirimulang">di sini</b-button></span>
                <b-progress
                  variant="warning"
                  :max="dismissSecs"
                  :value="dismissCountDown"
                  height="4px"
                ></b-progress>
              </b-alert>
              <form v-on:submit.prevent="login" role="form">
                <base-input
                  alternative
                  class="mb-3"
                  placeholder="Email"
                  v-model="email"
                  addon-left-icon="ni ni-email-83"
                >
                </base-input>
                <base-input
                  alternative
                  type="password"
                  placeholder="Password"
                  v-model="password"
                  addon-left-icon="ni ni-lock-circle-open"
                >
                </base-input>
                <!-- <base-checkbox>
                                    Ingat saya
                                </base-checkbox> -->
                <div class="myTest custom-control custom-checkbox mb-2">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck1"
                  />
                  <label class="custom-control-label" for="customCheck1"
                    >Ingat Saya</label
                  >
                </div>
                <vue-recaptcha
                  :sitekey="grecaptcha.siteKey"
                  :loadRecaptchaScript="true"
                  @verify="onCaptchaVerified"
                ></vue-recaptcha>
                <div class="text-center">
                  <base-button
                    nativeType="submit"
                    type="success"
                    class="my-4"
                    v-html="loginButton.caption"
                    :disabled="loginButton.disabled"
                  ></base-button>
                </div>
              </form>
            </template>
          </card>
          <div class="row mt-3">
            <div class="col-6">
              <a href="/lupapass" class="text-white">
                <small>Lupa password?</small>
              </a>
            </div>
            <div class="col-6 text-right">
              <a href="/register" class="text-white">
                <small>Buat Akun Baru</small>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import VueRecaptcha from "vue-recaptcha";
export default {
  name: "Login",
  components: { VueRecaptcha },

  data() {
    return {
      email: "",
      password: "",
      dismissSecs: 10,
      dismissCountDown: 0,
      alertMessage: "",
      error: "",
      variant: "warning",
      showDismissibleAlert: false,
      //gawe google recaptcha
      grecaptcha: {
        //key ne akun iai.kominfo.paskab@gmail.com
        siteKey: "6LdM9NgZAAAAAPqlj0NxFQDXDLA-cQIXKexcpPjU",
        responseToken: "",
      },
      loginButton: {
        caption: "Masuk",
        disabled: false,
      },
    };
  },
  methods: {
    onCaptchaVerified: function (recaptchaToken) {
      //recaptchaToken = response dr grecaptcha
      let self = this;
      self.grecaptcha.responseToken = recaptchaToken;
    },
    login: function () {
      let self = this;
      self.loginButton.caption = "<i class='fa fa-spinner fa-spin'></i> Masuk";
      self.loginButton.disabled = true;
      axios({
        method: "post",
        url: self.$config.url_api + "loginusers",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: {
          email: this.email,
          password: this.password,
          g_recaptcha_response: self.grecaptcha.responseToken,
        },
      }).then(function (response) {
        if (response.data.success == true) {
          self.$cookies.set("landing", response.data.data.token);
          // console.log(response.data.data.verif_status)
          if (response.data.data.verif_status == 3) {
            window.location.href = self.$config.base_url + "permohonansaya";
          } else {
            window.location.href = self.$config.base_url + "profile";
          }

          self.loginButton.caption =
            "<i class='fa fa-spinner fa-spin'></i> Mengalihkan";
          // self.$router.push('ListIzin');
          // window.location.href = self.$config.base_url;
        } else {
          self.showDismissibleAlert = true;
          self.dismissCountDown = 999999;
          self.alertMessage = response.data.message;
          self.error = response.data.data.error;
          self.variant = "warning";

          self.loginButton.caption = "Masuk";
          self.loginButton.disabled = false;
        }
      });
    },
    kirimulang() {
      let self = this;
      self.alertMessage = 'Loading...';
      self.error = "";
      axios({
        method: "post",
        url: self.$config.url_api + "sendemail",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: {
          email: this.email,
        },
      }).then(function (response) {
        if (response.data.success == true) {
          self.showDismissibleAlert = true;
          self.dismissCountDown = 999999;
          self.alertMessage = 'Berhasil mengirim ke email anda. Harap cek email anda. Cek halaman spamn jika diinbox tidak ada.';
          self.variant = "success";
        }
      });
    },
  },
};
</script>
<style>
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #2dce89 !important;
}

.custom-checkbox
  .custom-control-input:checked:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 255, 0, 0.25);
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}
.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  background-color: #c8ffc8;
}
</style>
