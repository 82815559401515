/*!
=========================================================
* powered by : dinas komunikasi dan informatika kabupaten pasuruan
=========================================================
*/
import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import Argon from "./plugins/argon-kit";
import BootstrapVue from "bootstrap-vue"

// import './registerServiceWorker'
import axios from 'axios'
import AxiosPlugin from 'vue-axios-cors';
Vue.use(AxiosPlugin)

import VueMoment from 'vue-moment';
Vue.use(VueMoment)
// Vue.prototype.moment = VueMoment

import config from './config/index.js'
Vue.prototype.$config = config

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect)

import VueToastr from "vue-toastr";
Vue.use(VueToastr, {
  /* OverWrite Plugin Options if you need */
});

router.beforeEach((to, from, next) => {
  // console.log(to)
  if (to.meta.requiresAuth) {
      if (Vue.$cookies.isKey('landing') && Vue.$cookies.get('landing')) {
          var self = this;
          // next()
          axios({
                method: 'GET',
                url: config.url_api+'setting/users/biodata',
                headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer '+Vue.$cookies.get('landing')
                }
            }).then(function (response) {
                if(response.data.status == 'ok'){
                  next()
                }
                else{
                  location.href = '/login';
                }
            }).catch(function (error) {
              location.href = '/login';
            });
      } else {
          location.href = '/login';
      }
  }
  next()
})

Vue.mixin({
  methods: {
    generateId: function (get_as_float) {
      // Returns either a string or a float containing the current time in seconds and microseconds    
      //   
      // version: 812.316  
      // discuss at: http://phpjs.org/functions/microtime  
      // +   original by: Paulo Ricardo F. Santos  
      // *     example 1: timeStamp = microtime(true);  
      // *     results 1: timeStamp > 1000000000 && timeStamp < 2000000000  
      var now = new Date().getTime() / 1000;
      var s = parseInt(now);
      var hasil = '';
      hasil = (get_as_float) ? now : (Math.round((now - s) * 1000) / 1000) + '' + s;
      return hasil.replace('0.', Math.floor((Math.random() * 99) + 11));
    }
  }
});
// set default config
Vue.$cookies.config('7d')
Vue.use(BootstrapVue);
Vue.config.productionTip = false;
Vue.use(Argon);
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
