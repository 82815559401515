<template>
  <div class="profile-page">
    <section class="section section-shaped section-lg my-0">
      <div class="shape shape-style-1 bg-gradient-success"></div>
      <div class="row row-grid justify-content-center">
        <div class="col-lg-8 text-center">
          <h2 class="text-white">Tracking Permohonan</h2>
        </div>
      </div>
    </section>

    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--4000">
          <div class="row">
            <b-col
              class="mb-3"
              md="12"
              style="text-align-last: right !important"
            >
              <b-button variant="primary" v-b-modal.modal-center
                ><i class="fa fa-clock-o"></i> Riwayat Verifikasi</b-button
              >
            </b-col>
            <b-modal
              id="modal-center"
              size="xl"
              title="Riwayat Verifikas Dokumen"
              ok-only
            >
              <b-list-group>
                <b-list-group-item
                  href="#"
                  class="flex-column align-items-start"
                  v-for="(row, index) in dataRiwayat"
                  :key="index"
                >
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">Keterangan</h5>
                    <small>{{ row.tanggal }}</small>
                  </div>

                  <p class="mb-1">
                    {{ row.deskripsi }}
                  </p>

                  <small v-if="row.oleh !== '-'">Oleh: {{ row.oleh }}</small>
                </b-list-group-item>
              </b-list-group>
            </b-modal>
            <b-card
              v-for="tab in items"
              :key="tab.id"
              no-body
              class="mb-1 no-border col-lg-12"
            >
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  :disabled="tab.disabled"
                  v-b-toggle="['collapse-' + tab.id]"
                  variant="grey"
                  class="text-left"
                >
                  VERIFIKASI TAHAP KE - {{ tab.id }}
                  <small>{{ tab.title }}</small>
                </b-button>
              </b-card-header>
              <b-collapse
                :id="'collapse-' + tab.id"
                :visible="tab.visible"
                class="p-3"
                accordion="my-accordion"
                width="100%"
                role="tabpanel"
              >
                <template>
                  <b-list-group
                    v-for="verifikator in tab.items"
                    :key="verifikator.id"
                    style="max-width: 100%"
                  >
                    <b-list-group-item class="d-flex align-items-center">
                      <b-avatar class="mr-3"></b-avatar>
                      <span class="mr-auto">{{
                        verifikator.verifikator_pengajuan
                      }}</span>
                      <b-badge v-bind:class="verifikator.badge">{{
                        verifikator.last_status
                      }}</b-badge>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex align-items-center">
                      <b-table
                        responsive
                        :striped="false"
                        :bordered="false"
                        :borderless="true"
                        :outlined="false"
                        :small="false"
                        :hover="false"
                        :fixed="true"
                        :no-border-collapse="false"
                        :items="verifikator.tracking_pengajuan"
                        :fields="[
                          { key: 'tanggal', label: 'tanngal', tdClass: 'tgl' },
                          {
                            key: 'deskripsi',
                            label: 'deskripsi',
                            tdClass: 'nontgl',
                          },
                          { key: 'revisi', label: 'revisi', tdClass: 'nontgl' },
                          { key: 'html', label: 'html', tdClass: 'nontgl' },
                        ]"
                        thead-class="hidden_header"
                      >
                        <template v-slot:cell(html)="data">
                          <span v-html="data.value"></span>
                        </template>
                        <template v-slot:cell(deskripsi)="data">
                          <span v-html="data.value"></span>
                        </template>

                        <template v-slot:cell(revisi)="data">
                          <span
                            v-if="revisiShow"
                            v-html="data.value"
                            @click="
                              editRevisi(
                                data.item.id,
                                data.item.status,
                                data.item.dokumen
                              )
                            "
                          ></span>
                        </template>
                      </b-table>
                    </b-list-group-item>
                  </b-list-group>
                </template>
              </b-collapse>
            </b-card>
            <b-modal
              ref="my-modal"
              title="DETAIL PEMBAYARAN RESTRIBUSI"
              variant="info"
              header-bg-variant="info"
              content-class="border-info"
              ok-only
              ok-variant="info"
              size="lg"
            >
              <b-row>
                <b-col sm="12">
                  <h3>Rumus : {{ rumus }}</h3>
                </b-col>
                <b-col sm="12">
                  <b-table
                    head-variant="light"
                    borderless
                    :items="items_modal"
                    :fields="fields_modal"
                  >
                  </b-table>
                </b-col>
                <b-col
                  md="12"
                  class="my-2"
                  v-for="(row, index) in restribusi"
                  :key="index"
                >
                  <b-card-group deck>
                    <b-card
                      bg-variant="dark"
                      :header="row.rincian"
                      text-variant="dark"
                      class="text-center"
                    >
                      <b-card-text class="text-white">
                        <strong>{{ row.totalrupiah }}</strong>
                      </b-card-text>

                      <b-card-text class="text-white">
                        {{ row.terbilang }}
                      </b-card-text>
                    </b-card>
                  </b-card-group>
                </b-col>
                <b-col md="12" class="my-2" v-if="keterangan_form.length">
                  <b-card-group deck>
                    <b-card
                      bg-variant="dark"
                      header="Keterangan"
                      text-variant="dark"
                      class="text-center"
                    >
                      <b-row
                        class="mb-2 text-white"
                        v-for="(row, index) in keterangan_form"
                        :key="index"
                      >
                        <b-col sm="6" class="text-sm-right"
                          ><b>{{ row.deskripsi }} :</b></b-col
                        >
                        <b-col class="text-sm-left">{{ row.value }}</b-col>
                      </b-row>
                    </b-card>
                  </b-card-group>
                </b-col>
                <b-col sm="12">
                  <b-card
                    bg-variant="dark"
                    :header="`Total yang harus dibayar: ${totalRestribusi}`"
                    header-class="headSize"
                    text-variant="dark"
                    class="text-left"
                  >
                    <b-card-body class="text-white">
                      <b-card-text>
                        {{ Keterangan }}
                      </b-card-text>
                      <b-row>
                        <b-col md="6" offset-md="3">
                          <b-img v-if="url" :src="url" fluid></b-img>
                        </b-col>
                      </b-row>
                      <div id="containerFile" ref="containerFile"></div>
                      <b-row>
                        <b-col md="6" offset-md="3">
                          <b-button
                            variant="outline-primary"
                            @click="uploadbukti"
                            >Upload Bukti Sekarang</b-button
                          >
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>
            </b-modal>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from "vue";

import Modal from "@/components/Modal.vue";
var Parser = require("expr-eval").Parser;
import axios from "axios";
import * as moment from "moment";
import "moment/locale/id";
import $ from "jquery";
import EventBus from "../config/eventBus";
var File = Vue.component("File", {
  template:
    '<b-row class="form-group">\
                        <b-col md="10">\
                          <b-form-group :label="label" :label-for="id" label-cols-sm="2">\
                            <b-form-file  :placeholder="placeholder" v-model="file" v-on:input="add" accept=".jpg, .png, .pdf, .jpeg"></b-form-file>\
                          </b-form-group>\
                        </b-col>\
                        <b-col md="2">\
                          <b-button @click="file = null" variant="outline-danger"><i class="fa fa-archive"></i> HAPUS</b-button>\
                        </b-col>\
                    </b-row>',
  props: ["label", "id", "index"],
  data: function () {
    return {
      file: null,
      message: "",
      placeholder: "Upload File....",
    };
  },
  created() {
    EventBus.$emit("addFile", null, this.index);
  },
  methods: {
    add() {
      EventBus.$emit("addFile", this.file, this.index);
    },
  },
});

export default {
  component: {
    Modal,
  },
  data() {
    return {
      modals: {
        modal1: false,
      },
      items_modal: [],
      items: [],
      fields_modal: [
        {
          key: "var",
          label: "Variabel",
        },
        {
          key: "text",
          label: "Keterangan",
        },
        {
          key: "nilai",
          label: "Nilai",
        },
      ],
      footClone: false,
      headVariant: null,
      revisiShow: true,
      rumus: "",
      restribusi: [],
      totalRestribusi: 0,
      totalrupiah: null,
      file: null,
      url: null,
      id: 0,
      Keterangan: "",
      indexFile: 0,
      disable_button: false,
      is_first_disable_button: false,
      formFile: [],
      keterangan_form: [],
      dokumen_output: null,
      dataRiwayat: ["dhsgfjhf"],
      rew: false,
    };
  },
  methods: {
    // riwayatVerif: function () {
    //   var idRiwayat = this.$router.history.current.params.nomorperizinan;
    //   $.ajax({
    //     url: this.$config.url_api + "izin/riwayat_verifikasi/" + idRiwayat,
    //     cache: false,
    //     contentType: false,
    //     processData: false,
    //     method: "GET",
    //     success: function (response) {
    //       if (response.status == "ok") {
    //         this.dataRiwayat = response.data;
    //       }
    //     },
    //   });
    //   // axios({
    //   //   method: "GET",
    //   //   url: this.$config.url_api + "izin/riwayat_verifikasi/" + idRiwayat,

    //   //   if (response.data.status == "ok") {

    //   // });
    // },
    moment: function (date) {
      moment.locale("id");
      return moment(date, "YYYY-MM-DD LTS").format("dddd, LLL");
    },
    onFileChange: function (e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    uploadbukti: function () {
      const dataku = new FormData();
      var self = this;
      var nomorperizinan = this.$router.history.current.params.nomorperizinan;
      dataku.append("id", this.id);
      dataku.append("nomorperizinan", nomorperizinan);
      var file = this.formFile;
      for (let i = 0; i < file.length; i++) {
        if (file[i] == null) {
          alert("Harap semua bukti diupload!");
          return;
        } else {
          dataku.append("file[" + i + "]", file[i]);
        }
      }
      $.ajax({
        url: self.$config.url_api + "izin/buktitransfer",
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
        success: function (response) {
          if (response.status == "ok") {
            self.$router.go();
          }
        },
      });
    },
    editRevisi(id, status, dokumen) {
      if (status == 2 || status == 101) {
        var nomorperizinan = this.$router.history.current.params.nomorperizinan;
        this.$router.push({
          path: "/revisi/" + id + "/" + nomorperizinan,
        });
      } else if (status == 5) {
        this.id = id;
        this.$refs["my-modal"].show();
        var nomor = this.$router.history.current.params.nomorperizinan;
        var self = this;
        axios({
          method: "GET",
          url: this.$config.url_api + "izin/restribusi/" + nomor,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + this.$cookies.get("landing"),
          },
        }).then(function (response) {
          if (response.data.status == "ok") {
            var pengali = null;
            var text = null;
            var nilai = null;
            if (response.data.data.pengali) {
              pengali = response.data.data.pengali.split("|");
              text = pengali[1];
              nilai = pengali[0];
            }

            self.Keterangan = response.data.data.kategori.keterangan_restribusi;
            var keterangan_form = response.data.data.keterangan;
            var jenis = JSON.parse(response.data.data.kategori.jenis_input);
            self.keterangan_form.splice(0, self.keterangan_form.length);
            jenis.forEach((row) => {
              if (row.isRestribusi === "true") {
                self.keterangan_form.push({
                  deskripsi: row.deskripsi,
                  value: keterangan_form[row.column],
                });
              }
            });

            self.rumus = response.data.data.kategori.rumus
              .split("*")
              .join("x")
              .split("")
              .join(" ");
            self.items_modal = response.data.data.rumus;

            var rincian = null;
            if (nilai) {
              rincian = "(" + self.rumus + ") x " + nilai;
            } else {
              rincian = self.rumus;
            }

            var param = [];
            var konstanta = {};

            self.items_modal.forEach((row, i) => {
              if (row.from === "form") {
                var arr_nilai = row.nilai.split(",");
                arr_nilai.forEach((n, index) => {
                  if (param[index] === undefined) {
                    param[index] = { [row.var]: n };
                  } else {
                    param[index] = {
                      ...param[index],
                      [row.var]: n,
                    };
                  }
                });
              } else {
                if (konstanta === undefined) {
                  konstanta = { [row.var]: row.nilai };
                } else {
                  konstanta = { ...konstanta, [row.var]: row.nilai };
                }
              }
            });

            var val = [];
            param.forEach((row) => {
              val.push({ ...row, ...konstanta });
            });

            self.restribusi.splice(0, self.restribusi.length);
            self.totalRestribusi = null;
            val.forEach((row) => {
              var parser = new Parser();
              var expr = null;
              if (nilai) {
                expr = parser.parse(
                  "(" + response.data.data.kategori.rumus + ")*" + nilai
                );
              } else {
                expr = parser.parse(response.data.data.kategori.rumus);
              }

              var rumus = rincian;
              for (var key in row) {
                if (row.hasOwnProperty(key)) {
                  rumus = rumus.replace(key, row[key]);
                }
              }

              self.restribusi.push({
                rincian: rumus,
                totalrupiah: self.formatRupiah(expr.evaluate(row)),
                terbilang: self.terbilang(expr.evaluate(row)),
              });
              self.totalRestribusi += expr.evaluate(row);
            });

            self.totalRestribusi = self.formatRupiah(self.totalRestribusi);

            var bukti = JSON.parse(
              response.data.data.kategori.bukti_restribusi
            ).split(",");
            $("#containerFile").empty();
            for (let i = 0; i < bukti.length; i++) {
              var ComponentClass = Vue.extend(File);
              var instance = new ComponentClass({
                propsData: { label: bukti[0], id: "file-" + i, index: i },
              });
              instance.$mount(); // pass nothing
              self.$refs.containerFile.appendChild(instance.$el);
              self.indexFile = i;
            }
          }
        });
      } else if (status == 7 || status == 4) {
        var self = this;
        var url = self.$config.file_url + "outputakhir/" + self.dokumen_output;
        window.open(url);
      }
    },
    formatRupiah(angka) {
      var bilangan = angka;

      var number_string = bilangan.toString(),
        sisa = number_string.length % 3,
        rupiah = number_string.substr(0, sisa),
        ribuan = number_string.substr(sisa).match(/\d{3}/g);
      var separator = "";
      if (ribuan) {
        separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }
      return "Rp. " + rupiah + ",-";
    },
    terbilang(bilangan) {
      bilangan = String(bilangan);
      var angka = new Array(
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0"
      );
      var kata = new Array(
        "",
        "Satu",
        "Dua",
        "Tiga",
        "Empat",
        "Lima",
        "Enam",
        "Tujuh",
        "Delapan",
        "Sembilan"
      );
      var tingkat = new Array("", "Ribu", "Juta", "Milyar", "Triliun");

      var panjang_bilangan = bilangan.length;

      /* pengujian panjang bilangan */
      if (panjang_bilangan > 15) {
        kaLimat = "Diluar Batas";
        return kaLimat;
      }

      /* mengambil angka-angka yang ada dalam bilangan, dimasukkan ke dalam array */
      for (let i = 1; i <= panjang_bilangan; i++) {
        angka[i] = bilangan.substr(-i, 1);
      }

      let i = 1;
      let j = 0;
      var kaLimat = "";

      /* mulai proses iterasi terhadap array angka */
      while (i <= panjang_bilangan) {
        var subkaLimat = "";
        var kata1 = "";
        var kata2 = "";
        var kata3 = "";

        /* untuk Ratusan */
        if (angka[i + 2] != "0") {
          if (angka[i + 2] == "1") {
            kata1 = "Seratus";
          } else {
            kata1 = kata[angka[i + 2]] + " Ratus";
          }
        }

        /* untuk Puluhan atau Belasan */
        if (angka[i + 1] != "0") {
          if (angka[i + 1] == "1") {
            if (angka[i] == "0") {
              kata2 = "Sepuluh";
            } else if (angka[i] == "1") {
              kata2 = "Sebelas";
            } else {
              kata2 = kata[angka[i]] + " Belas";
            }
          } else {
            kata2 = kata[angka[i + 1]] + " Puluh";
          }
        }

        /* untuk Satuan */
        if (angka[i] != "0") {
          if (angka[i + 1] != "1") {
            kata3 = kata[angka[i]];
          }
        }

        /* pengujian angka apakah tidak nol semua, lalu ditambahkan tingkat */
        if (angka[i] != "0" || angka[i + 1] != "0" || angka[i + 2] != "0") {
          subkaLimat =
            kata1 + " " + kata2 + " " + kata3 + " " + tingkat[j] + " ";
        }

        /* gabungkan variabe sub kaLimat (untuk Satu blok 3 angka) ke variabel kaLimat */
        kaLimat = subkaLimat + kaLimat;
        i = i + 3;
        j = j + 1;
      }

      /* mengganti Satu Ribu jadi Seribu jika diperlukan */
      if (angka[5] == "0" && angka[6] == "0") {
        kaLimat = kaLimat.replace("Satu Ribu", "Seribu");
      }

      return kaLimat + "Rupiah";
    },
  },
  created() {
    var id = this.$router.history.current.params.nomorperizinan;
    var self = this;
    var param = {
      method: "GET",
      url: this.$config.url_api + "izin/edit/" + id,
      // headers: {
      // 'Accept': 'application/json',
      // 'Authorization': 'Bearer '+this.$cookies.get('landing')
      // }
    };
    if(this.$cookies.get('landing')!=null){
      param = {
        method: "GET",
        url: this.$config.url_api + "izin/edit/" + id,
        headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+this.$cookies.get('landing')
        }
      };
    };
    axios(param).then(function (response) {
      if (response.data.status == "ok") {
        var tracking = response.data.data.tracking;
        var is_last_tracking = false;
        for (let i = 0; i < tracking.length; i++) {
          var dt_verifikator = [];
          for (let j = 0; j < tracking[i].list_verifikator.length; j++) {
            var verifikator = tracking[i].list_verifikator[j];
            var dt_record_tracking = [];
            for (let k = 0; k < verifikator.timelineItems.length; k++) {
              var verifikasi = verifikator.timelineItems;
              var tgl = new Date(verifikasi[k].created_at);
              var badge = "";
              var last_status = "";
              var date =
                tgl.getFullYear() +
                "-" +
                (tgl.getMonth() + 1) +
                "-" +
                tgl.getDate();
              var time =
                tgl.getHours() +
                ":" +
                tgl.getMinutes() +
                ":" +
                tgl.getSeconds();
              var tanggal = date + " " + time;

              if (verifikasi[k].status == 0 || verifikasi[k].status == 100) {
                if (verifikasi[k].status == 0) {
                  badge = "badge badge-info";
                  last_status = "Proses";
                } else {
                  badge = "badge badge-success";
                  last_status = "Di Setujui";
                }
                dt_record_tracking.push({
                  id: verifikasi[k].id,
                  tanggal: tanggal,
                  deskripsi: verifikasi[k].deskripsi,
                  revisi: "",
                  html: '<div class="alert alert-info" role="alert">CUSTOMER SERVICE</div>',
                  status: verifikasi[k].status,
                  dokumen: "",
                });
              } else if (verifikasi[k].status == 1) {
                badge = "badge badge-info";
                last_status = "Proses";
                dt_record_tracking.push({
                  id: verifikasi[k].id,
                  tanggal: tanggal,
                  deskripsi: verifikasi[k].deskripsi,
                  revisi: "",
                  html: '<div class="alert alert-info" role="alert">PROSES</div>',
                  status: verifikasi[k].status,
                  dokumen: "",
                });
              } else if (
                verifikasi[k].status == 2 ||
                verifikasi[k].status == 101
              ) {
                badge = "badge badge-warning";
                last_status = "Revisi";
                dt_record_tracking.push({
                  id: verifikasi[k].id,
                  tanggal: tanggal,
                  deskripsi: verifikasi[k].deskripsi,
                  revisi:
                    '<button type="button" ref="btn-revisi" class="btn btn-outline-primary btn-revisi">Revisi Sekarang</button>',
                  html: '<div class="alert alert-warning" role="alert">REVISI</div>',
                  status: verifikasi[k].status,
                  dokumen: "",
                });
              } else if (
                verifikasi[k].status == 22 ||
                verifikasi[k].status == 102
              ) {
                badge = "badge badge-warning";
                last_status = "Selesai Revisi";
                dt_record_tracking.push({
                  id: verifikasi[k].id,
                  tanggal: tanggal,
                  deskripsi: verifikasi[k].deskripsi,
                  revisi:
                    '<button type="button" ref="btn-revisi" class="btn btn-outline-primary btn-revisi" disabled>Revisi Sekarang</button>',
                  html: '<div class="alert alert-warning" role="alert">SELESAI REVISI</div>',
                  status: verifikasi[k].status,
                  dokumen: "",
                });
              } else if (verifikasi[k].status == 3) {
                badge = "badge badge-success";
                last_status = "Disetujui";
                var des = verifikasi[k].deskripsi;
                if (verifikasi[k].keterangan_rekomtek != null) {
                  des = des + " (" + verifikasi[k].keterangan_rekomtek + ")";
                }
                var beritaacara = ''; 
                if (verifikasi[k].beritaacara) {
                    beritaacara = '<a target="_blank" href="'+self.$config.file_url+'beritaacara/'+verifikasi[k].beritaacara+'"><button type="button" class="btn btn-outline-success btn-download" >Berita Acara</button></a>';
                }
                dt_record_tracking.push({
                  id: verifikasi[k].id,
                  tanggal: tanggal,
                  deskripsi: des,
                  revisi: beritaacara,
                  html: '<div class="alert alert-success" role="alert">DISETUJUI</div>',
                  status: verifikasi[k].status,
                  dokumen: "",
                });
              } else if (verifikasi[k].status == 33) {
                badge = "badge badge-success";
                last_status = "Verifikasi Rekomtek";
                var des = verifikasi[k].deskripsi;
                if (verifikasi[k].keterangan_rekomtek != null) {
                  des = des + " (" + verifikasi[k].keterangan_rekomtek + ")";
                }
                dt_record_tracking.push({
                  id: verifikasi[k].id,
                  tanggal: tanggal,
                  deskripsi: des + "<br><small><i>Sedang menunggu persetujuan rekomendasi teknis oleh kepala dinas terkait untuk dapat lanjut ke tahap berikutnya.</i></small>",
                  revisi: "",
                  html: '<div class="alert alert-success" role="alert">VERIFIKASI REKOMTEK</div>',
                  status: verifikasi[k].status,
                  dokumen: "",
                });
              } else if (verifikasi[k].status == 4) {
                badge = "badge badge-danger";
                last_status = "Dibatalkan";
                var dok = response.data.data.dokumen_output;
                if (!dok) {
                  dok = "";
                  self.revisiShow = false;
                } else {
                  self.revisiShow = true;
                  is_last_tracking = true;
                  self.dokumen_output = response.data.data.dokumen_output;
                }

                dt_record_tracking.push({
                  id: verifikasi[k].id,
                  tanggal: tanggal,
                  deskripsi: verifikasi[k].deskripsi,
                  revisi:
                    '<button type="button" ref="btn-restribusi" class="btn btn-outline-success btn-download" >Download Dokumen</button>',
                  html: '<div class="alert alert-danger" role="alert">DIBATALKAN</div>',
                  status: verifikasi[k].status,
                  dokumen: dok,
                });
              } else if (verifikasi[k].status == 5) {
                badge = "badge badge-info";
                last_status = "Proses";
                dt_record_tracking.push({
                  id: verifikasi[k].id,
                  tanggal: tanggal,
                  deskripsi: verifikasi[k].deskripsi,
                  revisi:
                    '<button type="button" ref="btn-restribusi" class="btn btn-outline-info btn-revisi" >Detail Pembayaran</button>',
                  html: '<div class="alert alert-info" role="alert">PROSES PEMBAYARAN RESTRIBUSI</div>',
                  status: verifikasi[k].status,
                  dokumen: "",
                });
              } else if (verifikasi[k].status == 55) {
                if (verifikasi[k].verified_at) {
                  badge = "badge badge-success";
                  last_status = "Pembayaran Valid";
                  dt_record_tracking.push({
                    id: verifikasi[k].id,
                    tanggal: tanggal,
                    deskripsi: verifikasi[k].deskripsi,
                    revisi: "",
                    html: '<div class="alert alert-info" role="alert">PEMBAYARAN VALID</div>',
                    status: verifikasi[k].status,
                    dokumen: "",
                  });
                } else {
                  badge = "badge badge-info";
                  last_status = "Proses";
                  dt_record_tracking.push({
                    id: verifikasi[k].id,
                    tanggal: tanggal,
                    deskripsi: verifikasi[k].deskripsi,
                    revisi: "",
                    html: '<div class="alert alert-warning" role="alert">MENUNGGU PROSES VERIFIKASI ADMIN</div>',
                    status: verifikasi[k].status,
                    dokumen: "",
                  });
                }
              } else if (verifikasi[k].status == 6) {
                badge = "badge badge-info";
                last_status = "Proses";
                dt_record_tracking.push({
                  id: verifikasi[k].id,
                  tanggal: tanggal,
                  deskripsi: verifikasi[k].deskripsi,
                  revisi: "",
                  html: '<div class="alert alert-info" role="alert">PROSES PENANDATANGANAN</div>',
                  status: verifikasi[k].status,
                  dokumen: "",
                });
              } else if (verifikasi[k].status == 66) {
                badge = "badge badge-success";
                last_status = "Selesai";
                dt_record_tracking.push({
                  id: verifikasi[k].id,
                  tanggal: tanggal,
                  deskripsi: verifikasi[k].deskripsi,
                  revisi: "",
                  html: '<div class="alert alert-success" role="alert">SELESAI PENANDATANGANAN</div>',
                  status: verifikasi[k].status,
                  dokumen: "",
                });
              } else if (verifikasi[k].status == "7") {
                self.dokumen_output = response.data.data.dokumen_output;
                badge = "badge badge-success";
                last_status = "Selesai";
                is_last_tracking = true;
                dt_record_tracking.push({
                  id: verifikasi[k].id,
                  tanggal: tanggal,
                  deskripsi: verifikasi[k].deskripsi,
                  revisi:
                    '<button type="button" ref="btn-restribusi" class="btn btn-outline-success btn-download" >Download Dokumen</button>',
                  html: '<div class="alert alert-success" role="alert">SELAMAT</div>',
                  status: verifikasi[k].status,
                  dokumen: response.data.data.dokumen,
                });
              }
            }
            dt_verifikator.push({
              id: verifikator.id,
              verifikator_pengajuan: verifikator.judul,
              badge: badge,
              last_status: last_status,
              tracking_pengajuan: dt_record_tracking,
            });
          }

          var visible = false;
          if (tracking[i].jml_disetujui == 0 || is_last_tracking == true) {
            visible = true;
          }
          if (is_last_tracking == false) {
            self.items.push({
              id: i + 1,
              title:
                "(Telah diverifikasi oleh " +
                tracking[i].jml_disetujui +
                " dari " +
                tracking[i].jml_verifikator +
                " verifikator)",
              disabled: self.disable_button,
              visible: visible,
              index: tracking[i].index,
              items: dt_verifikator,
              messageWhenNoItems: "Tidak ada data",
              status: "Status",
              dokumen: "",
            });
          } else {
            self.items.push({
              id: i + 1,
              title: "(Download Dokumen Perizinan)",
              disabled: self.disable_button,
              visible: visible,
              index: tracking[i].index,
              items: dt_verifikator,
              messageWhenNoItems: "Tidak ada data",
              status: "Status",
              dokumen: "",
            });
          }
          if (tracking[i].jml_disetujui == 0) {
            self.disable_button = true;
          }
        }
      }
    });
  },
  mounted() {
    EventBus.$on("addFile", (file, index) => {
      this.formFile[index] = file;
    });
    var id = this.$router.history.current.params.nomorperizinan;
    var asw = this;
    $.ajax({
      url: this.$config.url_api + "izin/riwayat_verifikasi/" + id,
      cache: false,
      contentType: false,
      processData: false,
      method: "GET",
      success: function (response) {
        if (response.status == "ok") {
          asw.dataRiwayat = response.data;
          // console.log(this.dataRiwayat);
          // this.$bvModal.msgBoxConfirm("Message", {
          //   noCloseOnBackdrop: true,
          //   noCloseOnEsc: true,
          // });
        }
      },
    });
  },
};
</script>
<style>
.hidden_header {
  display: none;
}
.card-header button small {
  float: right;
  text-transform: capitalize;
}
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}
.timeline[data-v-30ba92e0] {
  max-width: 100%;
}
.border-bottom {
  border-bottom: 1px solid #ccc;
}
.no-border {
  border: 0px !important;
}
#preview img {
  max-width: 100%;
  max-height: 500px;
}
.section-profile-cover {
  height: 300px !important;
}
.padding-left {
  padding-left: 50px;
}

.tgl {
  width: 21%;
}

.headSize {
  font-size: 1.5rem;
  font-weight: bold;
}

/* style timeline */
</style>
