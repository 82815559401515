<template>
  <div class="profile-page">
    <section class="section section-shaped section-lg my-0">
      <div class="shape shape-style-1 bg-gradient-success"></div>
      <div class="row row-grid justify-content-center">
        <div class="col-lg-8 text-center">
          <h2 class="text-white">Data Permohonan</h2>
        </div>
      </div>
    </section>

    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--4000">
          <b-row>
            <b-col sm="5" md="8" class="my-1">
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Masukkan text untuk mencari..."
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      :disabled="!filter"
                      @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col sm="7" md="1" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-row>
          <!-- Main table element -->
          <b-table
            responsive
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            @filtered="onFiltered"
          >
            <template v-slot:cell(id)="row">
              {{row.item.id}}
              <div v-if="row.item.notif != null">
                <span class="badge badge-primary">Progress Baru!</span>
              </div>
            </template>
            <template v-slot:cell(expired_date)="row">
              <span v-if="row.item.status">
                {{ row.item.expired_date }}
              </span>
              <span v-else-if="row.item.expired_date == 'Proses'">
                {{ row.item.expired_date }}
              </span>
              <span v-else class="text-warning">
                {{ row.item.expired_date }}
              </span>
            </template>
            <template v-slot:cell(actions)="row">
              <span v-if="row.item.dokumen_output">
                <b-button
                  :href="
                    $config.file_url + 'outputakhir/' + row.item.dokumen_output
                  "
                  target="_blank"
                  size="sm"
                  class="mr-1 btn-download"
                  variant="success"
                  style="width: 100%; margin-bottom: 10%"
                >
                  Download Dokumen
                </b-button>
                <span v-if="!row.item.status">
                  <b-button
                    v-if="row.item.form_perpanjangan != null"
                    :href="$config.base_url + 'perpanjangan/' + row.item.id"
                    target="_blank"
                    size="sm"
                    class="mr-1 btn-download"
                    variant="dark"
                    style="width: 100%; margin-bottom: 10%"
                  >
                    Perpanjang Izin
                  </b-button>
                  <b-button
                    v-else
                    @click="noFormperpanjangan()"
                    size="sm"
                    class="mr-1 btn-download"
                    variant="dark"
                    style="width: 100%; margin-bottom: 10%"
                  >
                    Perpanjang Izin
                  </b-button>
                </span>
              </span>
              <span v-else>
                <b-button
                  size="sm"
                  @click="info(row.item, row.index, $event.target)"
                  class="mr-1"
                  variant="info"
                  style="width: 100%; margin-bottom: 10%"
                >
                  Detail
                </b-button>
                <b-button
                  size="sm"
                  :href="$config.base_url + 'tracking/' + row.item.id"
                  class="mr-1"
                  variant="warning"
                  style="width: 100%"
                >
                  Tracking
                </b-button>
              </span>
            </template>
          </b-table>

          <!-- Info modal -->
          <b-modal
            :id="infoModal.id"
            title="DETAIL PERMOHONAN"
            variant="info"
            header-bg-variant="info"
            content-class="border-info"
            ok-only
            ok-variant="info"
            size="lg"
            @hide="resetInfoModal"
          >
            <b-row>
              <b-col md="12" class="mt-3">
                <b-card-group deck class="mt-1">
                  <b-card
                    no-body
                    header="Data dan Alamat Izin"
                    border-variant="info"
                    header-border-variant="info"
                    body-border-variant="info"
                    header-text-variant="info"
                  >
                    <b-list-group flush>
                      <b-list-group-item href="#"
                        ><span>{{ buatBaru }}</span></b-list-group-item
                      >
                      <b-list-group-item href="#"
                        ><span>{{ perorangan }}</span></b-list-group-item
                      >
                      <b-list-group-item href="#"
                        ><span class="mr-3">Alamat Izin : </span
                        ><span>{{ alamatIzin }}</span></b-list-group-item
                      >
                    </b-list-group>
                  </b-card>
                </b-card-group>
                <b-card-group
                  deck
                  class="mt-1"
                  v-if="perusahaan.nama"
                >
                  <b-card
                    no-body
                    header="Data Perusahaan"
                    border-variant="info"
                    header-border-variant="info"
                    body-border-variant="info"
                    header-text-variant="info"
                  >
                    <b-list-group flush>
                      <b-list-group-item href="#"
                        ><span class="mr-3">Nama : </span
                        ><span>{{ perusahaan.nama }}</span></b-list-group-item
                      >
                      <b-list-group-item href="#"
                        ><span class="mr-3">Email : </span
                        ><span>{{ perusahaan.email }}</span></b-list-group-item
                      >
                      <b-list-group-item href="#"
                        ><span class="mr-3">No. NPWP : </span
                        ><span>{{ perusahaan.npwp }}</span></b-list-group-item
                      >
                      <b-list-group-item href="#"
                        ><span class="mr-3">No. Telp : </span
                        ><span>{{ perusahaan.telp }}</span></b-list-group-item
                      >
                      <b-list-group-item href="#"
                        ><span class="mr-3">Alamat : </span
                        ><span>{{ perusahaan.alamat }}</span></b-list-group-item
                      >
                    </b-list-group>
                  </b-card>
                </b-card-group>
                <b-card-group deck class="mt-1">
                  <b-card
                    no-body
                    header="Data Pengajuan"
                    border-variant="info"
                    header-border-variant="info"
                    body-border-variant="info"
                    header-text-variant="info"
                  >
                    <b-list-group flush>
                      <b-list-group-item
                        href="#"
                        v-for="row in form"
                        v-bind:key="row.id"
                      >
                        <span class="mr-3">{{ row.label }} : </span>
                        <span>{{ row.value }}</span>
                      </b-list-group-item>
                    </b-list-group>
                    <div v-if="RestribusiMultiple.length">
                      <h3 class="mt-2 ml-2"><strong>Detail Data</strong></h3>
                      <b-row class="p-3 text-center">
                        <b-col
                          class="p-0 m-0"
                          v-for="(mul, index) in RestribusiMultiple"
                          :key="index"
                        >
                          <h6><strong>{{ mul.deskripsi }}</strong></h6>
                          <b-list-group>
                            <b-list-group-item v-for="(det, index_det) in mul.value" :key="`${index}_${index_det}`">
                              {{ det }}
                            </b-list-group-item>
                          </b-list-group>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card>
                </b-card-group>
                <b-card-group deck class="mt-1">
                  <b-card
                    no-body
                    header="Data Persyaratan"
                    border-variant="info"
                    header-border-variant="info"
                    body-border-variant="info"
                    header-text-variant="info"
                  >
                    <b-list-group flush>
                      <b-list-group-item
                        :href="$config.file_url + 'file/' + row.value"
                        target="_blank"
                        v-for="row in file"
                        v-bind:key="row.id"
                      >
                        <span class="mr-3">{{ row.label }} : </span>
                        <span v-if="row.value">
                          <b-button
                            variant="outline-info"
                            :href="$config.file_url + 'file/' + row.value"
                            target="_blank"
                            ><font-awesome-icon class="mr-2" icon="eye" />
                            Lihat
                          </b-button>
                        </span>
                        <span v-else>
                          <strong style="color: red;">TIDAK ADA FILE TERUPLOAD.</strong>
                        </span>
                      </b-list-group-item>
                    </b-list-group>
                  </b-card>
                </b-card-group>
              </b-col>
            </b-row>
          </b-modal>
        </card>
      </div>
    </section>
  </div>
</template>
<style>
</style>
<script>
import * as moment from "moment";
import "moment/locale/id";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlus,
  faPencilAlt,
  faTrash,
  faInfo,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faPlus, faPencilAlt, faTrash, faInfo, faEye);
import axios from "axios";
export default {
  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },
  data() {
    return {
      RestribusiMultiple: [],
      items: [],
      form: [],
      file: [],
      alamatIzin: "",
      buatBaru: "",
      perorangan: "",
      fields: [
        { key: "id", label: "Nomor", class: "text-center" },
        { key: "jenis", label: "Jenis Izin", class: "text-center" },
        { key: "created_at", label: "Waktu Submit", class: "text-center" },
        { key: "expired_date", label: "Kadaluarsa", class: "text-center" },
        { key: "actions", label: "Actions" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      perusahaan: {
        nama: null,
        email: null,
        npwp: null,
        // bidang_usaha: null,
        telp: null,
        alamat: null,
      },
    };
  },
  mounted() {
    // Set the initial number of items
    var self = this;

    axios({
      method: "GET",
      url: this.$config.url_api + "izin/permohonansaya",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$cookies.get("landing"),
      },
    }).then(function (response) {
      if (response.data.status == "ok") {
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        var data = response.data.data;

        for (let i = 0; i < data.length; i++) {
          var status = false;
          var varDate = new Date(data[i].expired_date);
          if (varDate > today) {
            status = true;
          }

          var expired_date = "Proses";
          if (data[i].expired_date) {
            expired_date = self.momentDate(data[i].expired_date);
          }

          self.items.push({
            id: data[i].id,
            kategori_id: data[i].kategori_id,
            form_perpanjangan: data[i].form_perpanjangan,
            jenis: data[i].jenis,
            dokumen_output: data[i].dokumen_output,
            created_at: self.moment(data[i].created_at),
            expired_date: expired_date,
            status: status,
            actions: data[i].actions,
            notif: data[i].notif,
          });
        }
        self.totalRows = data.length;
      }
    });
  },
  methods: {
    noFormperpanjangan() {
      alert(
        "Mohon maaf, untuk saat ini anda belum bisa melakukan perpanjangan online."
      );
    },
    moment: function (date) {
      moment.locale("id");
      return moment(date, "YYYY-MM-DD LTS").format("dddd, LLL");
    },
    momentDate: function (date) {
      moment.locale("id");
      return moment(date, "YYYY-MM-DD LTS").format("dddd, LL");
    },
    async info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
      var self = this;
      await axios({
        method: "GET",
        url:
          this.$config.url_api +
          "master/kategori/editByNomorPerizinan/" +
          item.id,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
      }).then(function (response) {
        if (response.data.status == "ok") {
          var jenis_input = JSON.parse(response.data.data.jenis_input);
          // let index = 0;
          // let indexFile = 0;
          var form = response.data.form;
          self.alamatIzin =
            form.alamat_lokasi +
            " (" +
            form.nama_desa +
            " " +
            form.nama_kecamatan +
            " " +
            form.nama_kabupaten +
            " " +
            form.nama_provinsi +
            ")";
          if (form.buatBaru == true) {
            self.buatBaru = "Buat Baru";
          } else {
            self.buatBaru = "Perpanjangan";
          }

          if (form.perorangan == true) {
            self.perorangan = "Pribadi";
          } else {
            self.buatBaru = "Perusahaan";
          }

          self.perusahaan.nama = form.perusahaan_nama;
          self.perusahaan.email = form.perusahaan_email;
          self.perusahaan.npwp = form.perusahaan_npwp;
          self.perusahaan.telp = form.perusahaan_telp;
          self.perusahaan.alamat = form.perusahaan_alamat;

          self.form = [];
          self.file = [];
          var rumus = response.data.rumus;
          for (let i = 0; i < jenis_input.length; i++) {
            var column = jenis_input[i].column.toLowerCase();
            var value = form[column];
            var lanjut = false;
            if (response.data.data.ismultiplerestribusi) {
              for (let j = 0; j < rumus.length; j++) {
                if (jenis_input[i].deskripsi == rumus[j].text) {
                  var val = value.split(",");
                  self.RestribusiMultiple.push({
                    deskripsi: jenis_input[i].deskripsi,
                    value: val,
                  });

                  lanjut = true;
                  break;
                }
              }
            }

            if (lanjut) {
              continue;
            }

            if (jenis_input[i].type != "file") {
              self.form.push({ label: jenis_input[i].deskripsi, value: value });
              // index++;
            } else if (jenis_input[i].type == "file") {
              self.file.push({ label: jenis_input[i].deskripsi, value: value });
              // indexFile++;
            }
          }
        }
      });
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>