<template>
  <header class="header-global">
    <base-nav class="navbar-main bg-gradient-success" expand>
      <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
        <img :src="$config.base_url + 'img/brand/white.png'" alt="logo" />
      </router-link>
      <ul class="navbar-nav navbar-nav-hover align-items-lg-center w-100 p-3">
        <base-dropdown class="nav-item" menu-classes="dropdown-menu-xl">
          <a
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
            role="button"
          >
            <i class="ni ni-ui-04 d-lg-none"></i>
            <span class="nav-link-inner--text">Panduan Penggunaan</span>
          </a>
          <div class="dropdown-menu-inner">
            <a
              v-if="notlogin"
              href="/register"
              class="media d-flex align-items-center"
            >
              <div class="icon icon-shape bg-primary rounded-circle text-white">
                <i class="ni ni-single-02"></i>
              </div>
              <div class="media-body ml-3">
                <h6 class="heading mb-md-1">Daftar Akun</h6>
                <p class="description d-none d-md-inline-block mb-0">
                  Dapatkan layanan kami sepenuhnya dengan mendaftarkan akun anda
                  terlebih dahulu.
                </p>
              </div>
            </a>
            <a href="/#pertanyaanumum" class="media d-flex align-items-center">
              <div class="icon icon-shape bg-yellow rounded-circle text-white">
                <i class="ni ni-air-baloon"></i>
              </div>
              <div class="media-body ml-3">
                <h5 class="heading mb-md-1">Pertanyaan Umum</h5>
                <p class="description d-none d-md-inline-block mb-0">
                  Ajukan pertanyaan anda seputar permohonan izin dst, dengan
                  langkah langkah sebagai berikut.
                </p>
              </div>
            </a>
          </div>
        </base-dropdown>
        <a
          slot="title"
          href="/#cekpermohonan"
          class="nav-link"
          data-toggle="dropdown"
          role="button"
          v-if="notlogin"
        >
          <i class="ni ni-collection d-lg-none"></i>
          <span class="nav-link-inner--text">Cek Permohonan</span>
        </a>
        <a
          slot="title"
          href="/permohonansaya"
          class="nav-link"
          data-toggle="dropdown"
          role="button"
          v-if="login"
        >
          <i class="ni ni-collection d-lg-none"></i>
          <span class="nav-link-inner--text" style="position:relative">Permohonan Saya
            <badge
              type="primary"
              style="
                position: absolute;
                color: #000;
                background-color: #fff;
                margin-left:4px;
                font-size:12px;
                font-weight:bold;
              "
              v-if="angkaNotif != 0"
              >{{angkaNotif}}</badge>
            </span>
        </a>
        <a
          slot="title"
          href="/ListIzin"
          class="nav-link"
          data-toggle="dropdown"
          role="button"
          v-if="login"
        >
          <i class="ni ni-collection d-lg-none"></i>
          <span class="nav-link-inner--text">Ajukan Permohonan</span>
        </a>
        <div class="col-lg-6 text-lg-right btn-wrapper" v-if="notlogin">
          <base-button
            tag="a"
            a
            href="/login"
            istarget="_self"
            class="mb-3 mb-sm-0 text-success"
            type="white"
          >
            <i class="fa fa-sign-in" aria-hidden="true"></i>
            Masuk
          </base-button>
          <base-button
            tag="a"
            a
            href="/register"
            class="mb-3 mb-sm-0 text-danger"
            type="white"
          >
            <i class="fa fa-sign-out" aria-hidden="true"></i>
            Daftar
          </base-button>
        </div>
        <div class="col-lg-4 text-lg-right" v-if="login">
          <base-dropdown
            class="nav-item"
            menu-classes="dropdown-menu-l"
            position="right"
          >
            <a
              slot="title"
              href="#"
              class="nav-link"
              data-toggle="dropdown"
              role="button"
              type="default"
            >
              <base-button type="white">
                <b-img
                  :src="foto"
                  rounded="circle"
                  class="mr-2 border border-white"
                  style="width: 2.2rem; height: 2.2rem"
                ></b-img>
                <span style="color: black">{{ nama }}</span>
              </base-button>
            </a>
            <div class="dropdown-menu-inner">
              <a
                :href="$config.base_url + 'profile'"
                class="media d-flex align-items-center"
              >
                <div class="media-body ml-3">
                  <h6 class="heading mb-md-1 primary">Profile</h6>
                </div>
              </a>
              <a
                :href="$config.base_url + 'pencabutan'"
                class="media d-flex align-items-center"
              >
                <div class="media-body ml-3">
                  <h6 class="heading mb-md-1 primary">Pencabutan Izin</h6>
                </div>
              </a>
              <a
                :href="$config.base_url + 'telegram'"
                class="media d-flex align-items-center"
              >
                <div class="media-body ml-3">
                  <h6 class="heading mb-md-1 primary">Sambungkan Telegram</h6>
                </div>
              </a>
              <a
                href="#"
                v-on:click="logout"
                class="media d-flex align-items-center"
              >
                <div class="media-body ml-3">
                  <h6 class="heading mb-md-1">logout</h6>
                </div>
              </a>
            </div>
          </base-dropdown>
        </div>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";
import axios from "axios";
import $ from "jquery";
export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
  },
  data() {
    return {
      login: true,
      notlogin: false,
      foto: "",
      nama: "",
      angkaNotif:0,
      suara: null,
    };
  },
  created() {
    this.suara = new Audio('/sound/bigbox.mp3'); // path to file
    var self = this;

    axios({
      method: "GET",
      url: this.$config.url_api + "setting/users/biodata",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$cookies.get("landing"),
      },
    })
      .then(function (response) {
        if (response.data.status == "ok") {
          self.login = true;
          self.notlogin = false;
          self.nama = response.data.data.nama_lengkap;
          if (response.data.data.foto == null) {
            self.foto = self.$config.images_url + "/users/no-images.jpg";
          } else {
            self.foto =
              self.$config.url_api + "fotoprofil/" + response.data.data.foto;
          }
        } else {
          self.notlogin = true;
          self.login = false;
        }
        
        if(self.login){
          self.pollNotif();
        }
      })
      .catch(function (error) {
        self.notlogin = true;
        self.login = false;
      });
  },
  methods: {
    notifikasi(teks){
      this.suara.pause();
      this.suara.currentTime = 0;
      this.suara.play();
      this.$toastr.Add({
        title: "Notifikasi", // Toast Title
        msg: teks, // Toast Message
        clickClose: true, // Click Close Disable
        position: "toast-top-right", // Toast Position.
        type: "info", // Toast type,
        // timeout: 0,
      });
    },

    pollNotif() {
      if(this.login == true){
        const self = this;

        axios({
          method: "GET",
          url: this.$config.url_api + "notifikasi/poll_landing",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + this.$cookies.get("landing"),
          },
        })
        .then(function (response) {
          if (response.data.status == "ok") {
            self.angkaNotif = response.data.data.notif.length;
            if(response.data.data.new.length>0){
              response.data.data.new.forEach(p => {
                self.notifikasi(`Ada progress baru pada permohonan ${p.nomorperizinan}. <br>
                <button class="btn btn-primary btn-sm" onclick="document.location.href='/tracking/${p.nomorperizinan}'">Lihat Permohonan</button>`);
              });
            }
            setTimeout(()=>{self.pollNotif()}, 5000);
          }else{
            setTimeout(()=>{self.pollNotif()}, 10000);
          }
        }).catch(function(e){
          setTimeout(()=>{self.pollNotif()}, 15000);
        });
      }
    },
    openTracking(no){
      document.location.href = "/tracking/"+no;
    },

    logout() {
      var self = this;

      axios({
        method: "POST",
        url: this.$config.url_api + "logout",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
      })
        .then(function (response) {
          // console.log(response.data)
          if (response.data.status == "ok") {
            self.$cookies.remove("landing");
            window.location.href = self.$config.base_url;
          }
        })
        .catch((error) => {
          // console.log(error)
        });
    },
  },
};
</script>
<style>
.dropdown-toggle:after {
  content: none !important;
}
</style>
