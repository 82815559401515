<template>
  <section class="section section-shaped section-lg my-0">
    <div class="shape shape-style-1 bg-gradient-success"></div>
    <section class="section section-lg pt-15">
      <div class="container">
        <card gradient="secondary" shadow body-classes="p-lg-5">
          <h4 class="text-success">Reset Password Anda.</h4>
          <p class="mt-0">Masukan alamat e-mail yang anda daftarkan.</p>

          <b-alert
            :show="dismissCountDown"
            dismissible
            :variant="variant"
            @dismissed="dismissCountDown = 0"
          >
            <p v-html="alertMessage"></p>
            
          </b-alert>
          <!-- Stack the columns on mobile by making one full-width and the other half-width -->
          <div class="row">
            <div class="col-12 col-md-10">
              <base-input
                alternative
                placeholder="E-mail"
                addon-left-icon="ni ni-email-83"
                v-model="email"
              >
              </base-input>
            </div>
            <div class="col-6 col-md-2">
              <base-button
                @click="sendemail"
                type="success"
                class="ml-auto"
                icon="ni ni-bold-right"
              >
                Kirim
              </base-button>
            </div>
          </div>
        </card>
      </div>
    </section>
  </section>
</template>
<script>
import axios from "axios";
export default {
  name: "lupaspass",
  data() {
    return {
      email: "",
      dismissSecs: 10,
      dismissCountDown: 0,
      alertMessage: "",
      showDismissibleAlert: false,
      variant: "danger",
    };
  },
  methods: {
    sendemail: function () {
      let self = this;
      axios({
        method: "post",
        url: self.$config.url_api + "emailforgot",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: {
          email: this.email,
        },
      }).then(function (response) {
        //console.log(response.data)
        if (response.data.success == true) {
          self.variant = "success";
          self.showDismissibleAlert = true;
          self.dismissCountDown = self.dismissSecs;
          self.alertMessage = response.data.message;
        } else {
          self.variant = "danger";
          self.showDismissibleAlert = true;
          self.dismissCountDown = self.dismissSecs;
          self.alertMessage = response.data.data;
        }
      });
    },
  },
};
</script>
<style>
</style>
