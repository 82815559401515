<template>
  <div class="profile-page">
    <section class="section section-shaped section-lg my-0">
      <div class="shape shape-style-1 bg-gradient-success"></div>
      <div class="row row-grid justify-content-center">
        <div class="col-lg-8 text-center">
          <h2 class="text-white">Revisi Layanan Perizinan</h2>
        </div>
      </div>
    </section>

    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--4000 pt-50">
          <div class="row">
            <form-wizard :title="items.nama" :subtitle="'Nomor Pendafdaftaran : '+$router.history.current.params.nomorperizinan" backButtonText="Kembali" nextButtonText="Selanjutnya" finishButtonText="Selesai" @on-complete="onComplete" shape="circle" color="#20a0ff" error-color="#ff4949">
              <tab-content title="Jenis Perizinan" :icon="iconFirst" :before-change="validateFirstStep">
                <form role="form" ref="ruleForm1">
                  <div class="col-md-12">
                    <div class="error" v-if="!$v.buatBaru.required">Harus memilih salah satu</div>
                  </div>
                  <div class="col-md-12">
                    <b-form-group label="Tipe Permohonan">
                      <b-form-radio-group
                        id="tipePemohon"
                        v-model.trim="$v.tipePemohon.$model"
                        name="tipePemohon"
                      >
                        <b-form-radio :value="true">Perorangan</b-form-radio>
                        <b-form-radio :value="false">Perusahaan</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <div class="error" v-if="!$v.tipePemohon.required">Harus memilih salah satu</div>
                  </div>
                  
                </form>
              </tab-content>
              <tab-content title="Jenis Perizinan" :icon="iconSecond" :before-change="validateSecondStep">
                <h3>Data Pemohon</h3>
                <div class="col-md-12">
                  <base-input alternative :value="users.nama_lengkap" disabled helpText="Nama yang dimasukan adalah nama pemohon sesuai dengan KTP"></base-input>
                </div>
                <div class="col-md-12">
                  <base-input alternative :value="users.email" disabled helpText="Pastikan anda selalu mengecek email, karena pemberitahuan permohonan akan dikirimkan ke email anda"></base-input>
                </div>
                <div class="col-md-12">
                  <base-input alternative :value="users.nohp" disabled helpText="Gunakan nomor handphone yang aktif, karena pemberitahuan permohonan juga akan dikirimkan lewat sms"></base-input>
                </div>
                <div class="col-md-12">
                  <base-input alternative :value="users.nohpkuasa" disabled placeholder="No Hp Kuasa" helpText="(Opsional) Gunakan nomor handphone yang aktif, karena pemberitahuan permohonan juga akan dikirimkan lewat sms"></base-input>
                </div>
                <div class="col-md-12">
                  <base-input alternative :value="users.alamat+' ('+users.nama_desa+' '+users.nama_kecamatan+' '+users.nama_kabupaten+' '+users.nama_provinsi" disabled placeholder="Alamat Lengkap" helpText="Alamat Lengkap sesuai KTP Pemohon"></base-input>
                </div>
                <h3>Alamat Izin</h3>
                <b-row class="form-group">
                  <b-col md-12>
                    <b-form-group
                      label="Provinsi *"
                      label-for="Provinsi"
                      :label-cols="2"
                      >
                      <v-select label="nama" :options="optionProvinsi" :reduce="optionProvinsi => optionProvinsi.id" v-model="provinsiIzin" disabled></v-select>
                      <div class="error" v-if="provinsiIzin == 0">Harap Pilih Provinsi</div>
                      <div class="help-text" v-else>Provinsi berhasil dipilih</div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="form-group">
                  <b-col md-12>
                    <b-form-group
                      label="Kabupaten *"
                      label-for="kabupaten"
                      :label-cols="2"
                      >
                      <v-select label="nama" :options="optionKabupaten" :reduce="optionKabupaten => optionKabupaten.id" v-model="kabupatenIzin" disabled></v-select>
                      <div class="error" v-if="kabupatenIzin == 0">Harap Pilih Kota/Kabupaten</div>
                      <div class="help-text" v-else>Kota/Kabupaten berhasil dipilih</div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="form-group">
                  <b-col md-12>
                    <b-form-group
                      label="Kecamatan *"
                      label-for="Kecamatan"
                      :label-cols="2"
                      >
                      <v-select label="nama" :options="optionKecamatan" :reduce="optionKecamatan => optionKecamatan.id" v-model="kecamatanIzin" @input="createDesa"></v-select>
                      <div class="error" v-if="kecamatanIzin == 0">Harap Pilih Kelurahan/Kecamatan</div>
                      <div class="help-text" v-else>Kelurahan/Kecamatan berhasil dipilih</div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="form-group">
                  <b-col md-12>
                    <b-form-group
                      label="Desa *"
                      label-for="desa"
                      :label-cols="2"
                      >
                      <v-select label="nama" :options="optionDesa" :reduce="optionDesa => optionDesa.id" v-model="desaIzin"></v-select>
                      <div class="error" v-if="desaIzin == 0">Harap Pilih Desa</div>
                      <div class="help-text" v-else>Desa berhasil dipilih</div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="form-group">
                  <b-col md-12>
                    <b-form-group
                      label="Alamat Lengkap *"
                      label-for="alamat_engkap"
                      :label-cols="2"
                      >
                      <base-input alternative placeholder="Alamat izin" v-model.trim.lazy="$v.alamatIzin.$model"></base-input>
                      <div class="error" v-if="!$v.alamatIzin.required">Alamat lengkap harus terisi</div>
                      <div class="help-text" v-else>Alamat lengkap berhasil terisi</div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <span v-if="!$v.tipePemohon.$model">
                  <h3>Data Perusahaan</h3>
                  <b-row class="form-group">
                    <b-col md-12>
                      <b-form-group
                        >
                        <label for="">Nama Perusahaan *</label>
                        <base-input alternative v-model.trim.lazy="$v.perusahaan.nama.$model"></base-input>
                        <div class="error" v-if="!$v.perusahaan.nama.required">Nama harus terisi</div>
                        <div class="help-text" v-else>Nama sudah terisi</div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="form-group">
                    <b-col md-12>
                      <b-form-group
                        >
                        <label for="">Email Perusahaan *</label>
                        <base-input alternative v-model.trim.lazy="$v.perusahaan.email.$model"></base-input>
                        <div class="error" v-if="!$v.perusahaan.email.required">Email harus terisi</div>
                        <div class="help-text" v-else>Email sudah terisi</div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="form-group">
                    <b-col md-12>
                      <b-form-group
                        >
                        <label for="">No. NPWP *</label>
                        <base-input alternative v-model.trim.lazy="$v.perusahaan.npwp.$model"></base-input>
                        <div class="error" v-if="!$v.perusahaan.npwp.required">No. NPWP harus terisi</div>
                        <div class="help-text" v-else>No. NPWP sudah terisi</div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="form-group">
                    <b-col md-12>
                      <b-form-group
                        >
                        <label for="">Bidang Usaha *</label>
                        <base-input alternative v-model.trim.lazy="$v.perusahaan.bidang_usaha.$model"></base-input>
                        <div class="error" v-if="!$v.perusahaan.bidang_usaha.required">Bidang usaha harus terisi</div>
                        <div class="help-text" v-else>Bidang usaha sudah terisi</div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="form-group">
                    <b-col md-12>
                      <b-form-group
                        >
                        <label for="">No. Telp *</label>
                        <base-input alternative v-model.trim.lazy="$v.perusahaan.telp.$model"></base-input>
                        <div class="error" v-if="!$v.perusahaan.telp.required">No. Telp harus terisi</div>
                        <div class="help-text" v-else>No. Telp sudah terisi</div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="form-group">
                    <b-col md-12>
                      <b-form-group
                        >
                        <label for="">Alamat Perusahaan *</label>
                        <base-input alternative v-model.trim.lazy="$v.perusahaan.alamat.$model"></base-input>
                        <div class="error" v-if="!$v.perusahaan.alamat.required">Alamat harus terisi</div>
                        <div class="help-text" v-else>Alamat sudah terisi</div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </span>
              </tab-content>
              <tab-content title="Isi Formulir" :icon="iconThird" :before-change="validateThirdStep">
                <div id="resetAll" ref="container"></div>
                <div v-if="items.ismultiplerestribusi">
                  <hr>
                  <p style="font-size:1rem;">
                    Harap menambahkan detail data dengan klik tombol dibawah. Anda dapat menambahkan lebih dari satu dengan cara yang sama.
                  </p>

                  <b-button v-b-modal.modal-prevent-closing variant="outline-success" size="md" class="mb-4">
                    Tambah Data
                  </b-button>

                  <b-modal
                    id="modal-prevent-closing"
                    ref="modal"
                    title="Form Detail (Anda dapat menambahkan data lebih dari satu)."
                    size="lg"
                    @show="resetModal"
                    @ok="handleOk"
                  >
                    <form ref="form" @submit.stop.prevent="handleSubmit">
                      <b-form-group
                        v-for="(todo, index) in RestribusiMultiple"
                        v-bind:key="index"
                        :label="`${todo.deskripsi}`"
                        :label-for="`form_detail_${index}`"
                        :invalid-feedback="`${todo.deskripsi} harus terisi.`"
                      >
                        <b-form-input
                          :id="`form_detail_${index}`"
                          type="number"
                          v-model="RestribusiMultiple[index].value"
                        ></b-form-input>
                      </b-form-group>
                    </form>
                  </b-modal>

                  <b-table
                    v-if="table_data.length" 
                    bordered
                    :items="table_data"
                    :fields="fields"
                    show-empty
                    responsive
                    :busy="isBusy"
                  >
                    <template #cell(action)="data">
                      <b-button variant="outline-danger" @click="deleteDetailData(data)">Hapus</b-button>
                    </template>

                    <template #table-busy>
                      <div class="text-center text-info my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>

                  </b-table>

                  <hr>
                </div>
              </tab-content>
              <tab-content title="Upload File" :icon="iconFourth" :before-change="validateFourthStep">
                <div id="resetAllFile" ref="containerFile"></div>
              </tab-content>
              <tab-content title="Ajukan" icon="fa fa-check">
                <h2>Ajukan Permohonan</h2>
                <h4>Syarat dan ketentuan pendaftaran permohonan</h4>
                <p>Dengan anda mengunjungi, mengakses, maupun memberikan informasi dengan mengisi informasi pada Layanan Perizinan Online (Selanjutnya disebut pemohon), Layanan Perizinan Online berhak menambah atau mengurangi peraturan maupun menambah syarat-syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dahulu.</p>
                <h4>Taggung jawab pemohon</h4>
                <p>
                  <ol type="a">
                    <li>Data email dan no telepon yang digunakan untuk melakukan permohonan izin wajib menggunakan email dan no telepon pemohon dan tidak menggunakan no telepon kuasa.</li>
                    <li>Memeriksa kembali kebenaran seluruh data yang akan dimohonkan, petugas tidak bertanggung jawab atas kesalahan penulisan.</li>
                    <li>Memastikan informasi yang diberikan dapat dibuktikan keaslian maupun keabsahannya ketika melakukan permohonan izin. Jika pemohon terbukti melakukan pemalsuan dokumen maka pemohon sanksi sesuai hukum yang berlaku.</li>
                    <li>Memeriksa secara berkala status permohonan.</li>
                  </ol>
                  Dengan Menekan Tombol "SELESAI" dibawah anda telah menyetujui syarat dan ketentuan kami yang berlaku.
                </p>
              </tab-content>
            </form-wizard>
          </div>
        </card>
      </div>
    </section>
    <b-modal 
      :id="errorModal.id" 
      ok-only
      danger
      ok-title="OK"
      :title="errorModal.title"
      >
      <p class="my-4">{{errorModal.message}}</p>
    </b-modal>
  </div>
</template>
<style>
.vue-form-wizard {
  width: 100%;
}

.error {
  font-style: normal;
  color: maroon;
  font-size: 0.8rem;
}

.help-text {
  font-style: normal;
  color: #11cdef;
  font-size: 0.8rem;
}
</style>
<script>
import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Vue from "vue";
Vue.use(VueFormWizard);
import EventBus from "../config/eventBus";

var Text = Vue.component("Text", {
  template:
    '<b-row class="form-group">\
                        <b-col md="12">\
                          <b-form-group\
                              :label="label"\
                              >\
                              <b-form-input type="text"  placeholder="Enter Text..." v-model="text" @input="check"></b-form-input>\
                              <div class="error" v-if="param"> {{ message }}</div>\
                            <div class="help-text" v-else>{{ message }}</div>\
                          </b-form-group>\
                        </b-col>\
                    </b-row>',
  props: ["label", "id", "index", "mandatory", "value"],
  data: function () {
    return {
      text: "",
      param: true,
      message: "",
    };
  },
  created() {
    this.text = this.value;
    if (this.mandatory == "true") {
      this.param = true;
      this.message = this.label + " harus Terisi";
    } else {
      this.param = false;
      this.message = "optional";
    }

    if (this.mandatory == "true") {
      this.param = true;
      this.message = this.label + " harus terisi";
    } else {
      this.param = false;
      this.message = "";
      this.message = "optional";
    }

    if (this.text != "") {
      this.param = false;
      this.message = this.label + " telah terisi";
      EventBus.$emit("addText", this.text, this.index, this.mandatory);
    } else {
      EventBus.$emit("addText", null, this.index, null);
    }
  },
  methods: {
    check() {
      if (this.mandatory == "true") {
        this.param = true;
        this.message = this.label + " harus terisi";
      } else {
        this.param = false;
        this.message = "";
        this.message = "optional";
      }

      if (this.text != "") {
        this.param = false;
        this.message = this.label + " telah terisi";
        EventBus.$emit("addText", this.text, this.index, this.mandatory);
      } else {
        EventBus.$emit("addText", null, this.index, null);
      }
    },
  },
});

var Nomber = Vue.component("Nomber", {
  template:
    '<b-row class="form-group">\
                        <b-col md="12">\
                          <b-form-group\
                              :label="label"\
                              >\
                              <b-form-input type="number"  placeholder="Enter Text..." v-model="text" @input="check"></b-form-input>\
                              <div class="error" v-if="param"> {{ message }}</div>\
                            <div class="help-text" v-else>{{ message }}</div>\
                          </b-form-group>\
                        </b-col>\
                    </b-row>',
  props: ["label", "id", "index", "mandatory", "value"],
  data: function () {
    return {
      text: "",
      param: true,
      message: "",
    };
  },
  created() {
    this.text = this.value;
    if (this.mandatory == "true") {
      this.param = true;
      this.message = this.label + " harus Terisi";
    } else {
      this.param = false;
      this.message = "optional";
    }

    if (this.mandatory == "true") {
      this.param = true;
      this.message = this.label + " harus terisi";
    } else {
      this.param = false;
      this.message = "";
      this.message = "optional";
    }

    if (this.text != "") {
      this.param = false;
      this.message = this.label + " telah terisi";
      EventBus.$emit("addText", this.text, this.index, this.mandatory);
    } else {
      EventBus.$emit("addText", null, this.index, null);
    }
  },
  methods: {
    check() {
      if (this.mandatory == "true") {
        this.param = true;
        this.message = this.label + " harus terisi";
      } else {
        this.param = false;
        this.message = "";
        this.message = "optional";
      }

      if (this.text != "") {
        this.param = false;
        this.message = this.label + " telah terisi";
        EventBus.$emit("addText", this.text, this.index, this.mandatory);
      } else {
        EventBus.$emit("addText", null, this.index, null);
      }
    },
  },
});

var Tanggal = Vue.component("Tanggal", {
  template:
    '<b-row class="form-group">\
                        <b-col md="12">\
                          <b-form-group\
                              :label="label"\
                              >\
                              <b-form-input type="date"  placeholder="Enter Text..." v-model="text" @input="check"></b-form-input>\
                              <div class="error" v-if="param"> {{ message }}</div>\
                            <div class="help-text" v-else>{{ message }}</div>\
                          </b-form-group>\
                        </b-col>\
                    </b-row>',
  props: ["label", "id", "index", "mandatory", "value"],
  data: function () {
    return {
      text: "",
      param: true,
      message: "",
    };
  },
  created() {
    EventBus.$emit("addText", null, this.index, this.mandatory);
    if (this.mandatory == "true") {
      this.param = true;
      this.message = this.label + " harus Terisi";
    } else {
      this.param = false;
      this.message = "optional";
    }
  },
  methods: {
    check() {
      if (this.mandatory == "true") {
        this.param = true;
        this.message = this.label + " harus terisi";
      } else {
        this.param = false;
        this.message = "";
        this.message = "optional";
      }

      if (this.text != "") {
        this.param = false;
        this.message = this.label + " telah terisi";
        EventBus.$emit("addText", this.text, this.index, this.mandatory);
      } else {
        EventBus.$emit("addText", null, this.index, null);
      }
    },
  },
});

var TextArea = Vue.component("TextArea", {
  template:
    '<b-row class="form-group">\
                        <b-col md="12">\
                          <b-form-group\
                              :label="label"\
                              >\
                              <b-form-textarea :rows="5" placeholder="Enter Text.." v-model="text" @input="check"></b-form-textarea>\
                              <div class="error" v-if="param"> {{ message }}</div>\
                            <div class="help-text" v-else>{{ message }}</div>\
                          </b-form-group>\
                        </b-col>\
                    </b-row>',
  props: ["label", "id", "index", "mandatory", "value"],
  data: function () {
    return {
      text: "",
      param: true,
      message: "",
    };
  },
  created() {
    this.text = this.value;
    if (this.mandatory == "true") {
      this.param = true;
      this.message = this.label + " harus Terisi";
    } else {
      this.param = false;
      this.message = "optional";
    }

    if (this.mandatory == "true") {
      this.param = true;
      this.message = this.label + " harus terisi";
    } else {
      this.param = false;
      this.message = "";
      this.message = "optional";
    }

    if (this.text != "") {
      this.param = false;
      this.message = this.label + " telah terisi";
      EventBus.$emit("addText", this.text, this.index, this.mandatory);
    } else {
      EventBus.$emit("addText", null, this.index, null);
    }
  },
  methods: {
    check() {
      if (this.mandatory == "true") {
        this.param = true;
        this.message = this.label + " harus terisi";
      } else {
        this.param = false;
        this.message = "";
        this.message = "optional";
      }

      if (this.text != "") {
        this.param = false;
        this.message = this.label + " telah terisi";
        EventBus.$emit("addText", this.text, this.index, this.mandatory);
      } else {
        EventBus.$emit("addText", null, this.index, null);
      }
    },
  },
});

var Radio = Vue.component("Radio", {
  template:
    '<b-row class="form-group">\
                        <b-col md="12">\
                          <b-form-group\
                              :label="label"\
                              >\
                              <b-form-radio-group\
                              :plain="true"\
                              class="ml-3"\
                              :options="optionsRadio"\
                              v-model="value"\
                              v-on:input="check"\
                              stacked>\
                            </b-form-radio-group>\
                            <div class="error" v-if="param"> {{ message }}</div>\
                            <div class="help-text" v-else>{{ message }}</div>\
                          </b-form-group>\
                        </b-col>\
                    </b-row>',
  props: ["label", "id", "optionsRadio", "index", "mandatory", "valueData"],
  data: function () {
    return {
      value: null,
      param: true,
      message: "",
    };
  },
  created() {
    this.value = this.valueData;
    if (this.mandatory == "true") {
      this.param = true;
      this.message = this.label + " Harus dipilih salah satu";
    } else {
      this.param = false;
      this.message = "";
      this.message = "optional";
    }

    if (this.value != null) {
      this.param = false;
      this.message = this.label + " telah dipilih";
      EventBus.$emit("addText", this.value, this.index, this.mandatory);
    } else {
      EventBus.$emit("addText", null, this.index, null);
    }
  },
  methods: {
    check() {
      if (this.mandatory == "true") {
        this.param = true;
        this.message = this.label + " Harus dipilih salah satu";
      } else {
        this.param = false;
        this.message = "optional";
      }

      if (this.value != null) {
        this.param = false;
        this.message = this.label + " telah dipilih";
        EventBus.$emit("addText", this.value, this.index, this.mandatory);
      } else {
        EventBus.$emit("addText", null, this.index, null);
      }
    },
  },
});

var Select = Vue.component("Select", {
  template:
    '<b-row class="form-group">\
                        <b-col md="12">\
                          <b-form-group\
                              :label="label"\
                              >\
                              <b-form-select :options="option" v-model="value" v-on:input="check"></b-form-select>\
                              <div class="error" v-if="param"> {{ message }}</div>\
                            <div class="help-text" v-else>{{ message }}</div>\
                          </b-form-group>\
                        </b-col>\
                    </b-row>',
  props: ["label", "id", "optionsSelect", "index", "mandatory", "valueData"],
  data: function () {
    return {
      value: null,
      param: true,
      message: "",
      option: [{ value: null, text: "-- PILIH --" }],
    };
  },
  created() {
    this.value = this.valueData;
    for (let i = 0; i < this.optionsSelect.length; i++) {
      this.option.push(this.optionsSelect[i]);
    }
    if (this.mandatory == "true") {
      this.param = true;
      this.message = this.label + " Harus dipilih salah satu";
    } else {
      this.param = false;
      this.message = "";
      this.message = "optional";
    }

    if (this.value != null) {
      this.param = false;
      this.message = this.label + " telah dipilih";
      EventBus.$emit("addText", this.value, this.index, this.mandatory);
    } else {
      EventBus.$emit("addText", null, this.index, null);
    }
  },
  methods: {
    check() {
      if (this.mandatory == "true") {
        this.param = true;
        this.message = this.label + " Harus dipilih salah satu";
      } else {
        this.param = false;
        this.message = "optional";
      }

      if (this.value != null) {
        this.param = false;
        this.message = this.label + " telah dipilih";
        EventBus.$emit("addText", this.value, this.index, this.mandatory);
      } else {
        EventBus.$emit("addText", null, this.index, null);
      }
    },
  },
});

var Checkbox = Vue.component("Checkbox", {
  template:
    '<b-row class="form-group">\
                        <b-col md="12">\
                          <b-form-group\
                              :label="label"\
                              >\
                              <b-form-checkbox-group\
                                :plain="true"\
                                class="ml-3"\
                                :options="optionsCheckbox"\
                                v-on:input="check"\
                                v-model="value"\
                                stacked\
                              ></b-form-checkbox-group>\
                              <div class="error" v-if="param"> {{ message }}</div>\
                            <div class="help-text" v-else>{{ message }}</div>\
                          </b-form-group>\
                        </b-col>\
                    </b-row>',
  props: ["label", "id", "optionsCheckbox", "index", "mandatory", "valueData"],
  data: function () {
    return {
      value: [],
      param: true,
      message: "",
    };
  },
  created() {
    this.value = this.valueData.split(",");
    if (this.mandatory == "true") {
      this.param = true;
      this.message = this.label + " Harus dipilih salah satu";
    } else {
      this.param = false;
      this.message = "optional";
    }

    if (this.value.length != 0) {
      this.param = false;
      this.message = this.label + " telah dipilih";
      EventBus.$emit("addText", this.value, this.index, this.mandatory);
    } else {
      EventBus.$emit("addText", null, this.index, this.mandatory);
    }
  },
  methods: {
    check() {
      if (this.mandatory == "true") {
        this.param = true;
        this.message = this.label + " Harus dipilih salah satu";
      } else {
        this.param = false;
        this.message = "optional";
      }

      if (this.value.length != 0) {
        this.param = false;
        this.message = this.label + " telah dipilih";
        EventBus.$emit("addText", this.value, this.index, this.mandatory);
      } else {
        EventBus.$emit("addText", null, this.index, this.mandatory);
      }
    },
  },
});

var File = Vue.component("File", {
  template:
    '<b-row class="form-group">\
                        <b-col md="8">\
                          <b-form-group :label="label" label-cols-sm="5">\
                            <b-form-file :state="Boolean(file)"  v-model="file" v-on:input="check" accept=".jpg, .png, .pdf, .jpeg, .doc"></b-form-file>\
                            <div class="mt-3">Selected file : {{ value }} <base-button  tag="a" istarget="_blank" :href="$config.file_url+\'file/\'+value" target="_blank" size="sm" outline type="success" icon="fa fa-eye">Lihat</base-button></div>\
                            <div class="error" v-if="param"> {{ message }}</div>\
                            <div class="help-text" v-else>{{ message }}</div>\
                          </b-form-group>\
                        </b-col>\
                        <b-col md="4">\
                          <base-button outline type="info" icon="ni ni-archive-2">Arsip</base-button>\
                        </b-col>\
                    </b-row>',
  props: ["label", "id", "index", "mandatory", "value"],
  data: function () {
    return {
      file: null,
      param: true,
      message: "",
      nilainya: "",
    };
  },
  created() {
    EventBus.$emit("addFile", null, this.index, this.mandatory);
  },
  methods: {
    check() {
      if (this.file.name != "") {
        this.param = false;
        this.message = this.label + " telah terisi";
        EventBus.$emit("addFile", this.file, this.index, this.mandatory);
      } else {
        EventBus.$emit("addFile", null, this.index, this.mandatory);
      }
    },
  },
});

import { required, minLength } from "vuelidate/lib/validators";
import axios from "axios";
import $ from "jquery";
export default {
  data() {
    return {
      users: [],
      items: [],
      input: [],
      form: [],
      formFile: [],
      mandatoryParam: [],
      mandatoryIndex: 0,
      mandatoryParamFile: [],
      mandatoryIndexFile: 0,
      iconFirst: "fa fa-times",
      iconSecond: "fa fa-times",
      iconThird: "fa fa-times",
      iconFourth: "fa fa-times",
      buatBaru: true,
      tipePemohon: true,
      optionProvinsi: [],
      optionKabupaten: [],
      optionKecamatan: [],
      optionDesa: [],
      provinsiIzin: 0,
      kabupatenIzin: 0,
      kecamatanIzin: 0,
      desaIzin: 0,
      name: "",
      alamatIzin: "",
      perusahaan: {
        nama: null,
        email: null,
        npwp: null,
        bidang_usaha: null,
        telp: null,
        alamat: null,
      },
      errorModal: {
        id: "modal-error",
        title: "Kesalahan",
        message: "",
      },
      table_data: [],
      fields: [],
      RestribusiMultiple: [],
      RestribusiMultipleTampungan: [],
      isBusy: false,
    };
  },
  validations: {
    buatBaru: {
      required,
    },
    tipePemohon: {
      required,
    },
    name: {
      required,
      minLength: minLength(4),
    },
    alamatIzin: {
      required,
    },
    perusahaan: {
      nama: { required },
      email: { required },
      npwp: { required },
      bidang_usaha: { required },
      telp: { required },
      alamat: { required },
    },
  },
  mounted() {
    EventBus.$on("addText", (text, index, mandatory) => {
      this.form[index] = text;
      this.mandatoryParam[index] = mandatory;
    });

    EventBus.$on("addFile", (file, index, mandatory) => {
      this.formFile[index] = file;
      this.mandatoryParamFile[index] = mandatory;
    });
  },
  methods: {
    createKabupaten() {
      var self = this;

      axios({
        method: "GET",
        url: this.$config.url_api + "kabupaten/" + this.provinsiIzin,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
      }).then(function (response) {
        if (response.data.status) {
          self.optionKabupaten = response.data.data;
        }
      });
    },
    createKecamatan() {
      var self = this;

      axios({
        method: "GET",
        url: this.$config.url_api + "kecamatan/" + this.kabupatenIzin,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
      }).then(function (response) {
        if (response.data.status) {
          self.optionKecamatan = response.data.data;
        }
      });
    },
    createDesa() {
      var self = this;

      axios({
        method: "GET",
        url: this.$config.url_api + "desa/" + this.kecamatanIzin,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
      }).then(function (response) {
        if (response.data.status) {
          self.optionDesa = response.data.data;
        }
      });
    },
    onComplete: function () {
      // $(".wizard-btn").prop("disabled", true);
      $(".wizard-footer-right .wizard-btn").text("loading...");
      var id = this.$router.history.current.params.id;
      var nomorperizinan = this.$router.history.current.params.nomorperizinan;
      var form = this.form;
      var file = this.formFile;
      var buatBaru = this.buatBaru;
      var tipePemohon = this.tipePemohon;
      var desa_id = this.desaIzin;
      var alamat_lokasi = this.alamatIzin;
      var perusahaan_nama = this.perusahaan.nama;
      var perusahaan_email = this.perusahaan.email;
      var perusahaan_npwp = this.perusahaan.npwp;
      var perusahaan_bidang_usaha = this.perusahaan.bidang_usaha;
      var perusahaan_telp = this.perusahaan.telp;
      var perusahaan_alamat = this.perusahaan.alamat;
      const dataku = new FormData();
      var self = this;
      dataku.append("nomorperizinan", nomorperizinan);
      dataku.append("id", id);
      for (let i = 0; i < form.length; i++) {
        dataku.append("form[" + i + "]", form[i]);
      }

      for (let i = 0; i < file.length; i++) {
        dataku.append("file[" + i + "]", file[i]);
      }
      dataku.append("buatbaru", buatBaru);
      dataku.append("tipepemohon", tipePemohon);
      dataku.append("desa_id", desa_id);
      dataku.append("alamat_lokasi", alamat_lokasi);
      if(!this.tipePemohon){
        dataku.append("perusahaan_nama", perusahaan_nama);
        dataku.append("perusahaan_email", perusahaan_email);
        dataku.append("perusahaan_npwp", perusahaan_npwp);
        dataku.append("perusahaan_bidang_usaha", perusahaan_bidang_usaha);
        dataku.append("perusahaan_telp", perusahaan_telp);
        dataku.append("perusahaan_alamat", perusahaan_alamat);
      }

      $.ajax({
        url: self.$config.url_api + "izin/update",
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
        success: function (response) {
          if (response.status == "ok") {
            self.$router.push({
              path: "/tracking/" + nomorperizinan,
            });
          } else if (response.status == "error") {
            // alert("Tidak bisa merubah.\n"+response.message);
            self.errorModal.title = "Tidak bisa merubah";
            self.errorModal.message = response.message;
            self.$bvModal.show(self.errorModal.id);
            // self.$refs['modal-error'].show()
          } else {
            alert("Terjadi kesalahan saat mengirim data.\nHarap hubungi CS");
          }
        },
      });
    },
    validateFirstStep() {
      this.$v.$touch();
      if (this.$v.buatBaru.$invalid || this.$v.tipePemohon.$invalid) {
        this.iconFirst = "fa fa-times";
        return false;
      } else {
        this.iconFirst = "fa fa-check";
        return true;
      }
    },
    validateSecondStep() {
      this.$v.$touch();
      if (
        this.provinsiIzin == 0 ||
        this.kabupatenIzin == 0 ||
        this.kecamatanIzin == 0 ||
        this.desaIzin == 0 ||
        this.$v.alamatIzin.$invalid
      ) {
        this.iconFirst = "fa fa-times";
        return false;
      } else {
        this.iconFirst = "fa fa-check";
        return true;
      }
    },
    validateThirdStep() {
      var form = this.form;
      var mandatory = this.mandatoryParam;
      let param = 0;
      for (let i = 0; i < form.length; i++) {
        if (mandatory[i] == "true" && form[i] != null) {
          param++;
        }
      }

      if (this.mandatoryIndex != param) {
        this.iconFirst = "fa fa-times";
        alert("Data tidak valid, harap cek kembali!");
        return false;
      } else {
        this.iconFirst = "fa fa-check";
        return true;
      }
    },
    validateFourthStep() {
      return true;
    },
    deleteDetailData(data) {
      this.table_data.splice(data.index, 1);
      var param = data.item.param;
      param.forEach(row => {
        this.form[row].splice(data.index, 1);
      });
    },
    resetModal() {
      var items = this.RestribusiMultiple;
      for (let i = 0; i < items.length; i++) {
        this.RestribusiMultiple[i].value = 0;
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      var items = this.RestribusiMultiple;
      
      var obj = {};
      var param = [];
      for (let i = 0; i < items.length; i++) {
        this.form[items[i].index].push(items[i].value);
        param.push(items[i].index);
        obj[items[i].column] = items[i].value;
      }
      obj = {...obj, param: param};
      
      this.table_data.push(obj);
      
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
  },
  async created() {
    var self = this;
    var nomorperizinan = this.$router.history.current.params.nomorperizinan;
    await axios({
      method: "GET",
      url: this.$config.url_api + "setting/users/biodata",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$cookies.get("landing"),
      },
    }).then(function (response) {
      if (response.data.status) {
        self.users = response.data.data;
      }
    });

    await axios({
      method: "GET",
      url:
        this.$config.url_api +
        "master/kategori/editByNomorPerizinan/" +
        nomorperizinan,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$cookies.get("landing"),
      },
    }).then(function (response) {
      // console.log(response.data)
      if (response.data.status) {
        var form = response.data.form;
        self.desaIzin = form.desa_id;
        self.kecamatanIzin = form.kecamatan_id;
        self.kabupatenIzin = form.kabupaten_id;
        self.provinsiIzin = form.provinsi_id;

        self.tipePemohon = form.perorangan;
        self.perusahaan.nama = form.perusahaan_nama;
        self.perusahaan.alamat = form.perusahaan_alamat;
        self.perusahaan.bidang_usaha = form.perusahaan_bidang_usaha;
        self.perusahaan.email = form.perusahaan_email;
        self.perusahaan.npwp = form.perusahaan_npwp;
        self.perusahaan.telp = form.perusahaan_telp;

        self.createKabupaten();
        self.createKecamatan();
        self.createDesa();

        self.alamatIzin = form.alamat_lokasi;

        self.items = response.data.data;
        var rumus = response.data.rumus;

        var jenis_input = JSON.parse(response.data.data.jenis_input);
        let index = 0;
        let indexFile = 0;
        for (let i = 0; i < jenis_input.length; i++) {
          var lanjut = false;
          var column = jenis_input[i].column.toLowerCase();
          var value = form[column];
          if (response.data.data.ismultiplerestribusi) {
            for (let j = 0; j < rumus.length; j++) {
              if (jenis_input[i].deskripsi == rumus[j].text) {
                var val = value.split(",");
                self.RestribusiMultiple.push({
                  deskripsi: jenis_input[i].deskripsi,
                  column: jenis_input[i].column,
                  value: 0,
                  index: index,
                });

                self.RestribusiMultipleTampungan.push({
                  deskripsi: jenis_input[i].deskripsi,
                  column: jenis_input[i].column,
                  value: val,
                  index: index,
                });

                self.fields.push({
                  key: jenis_input[i].column,
                  label: jenis_input[i].deskripsi,
                });

                lanjut = true;
                break;
              }
            }
          }

          if (lanjut) {
            self.form.push([]);
            index++;
            continue;
          }
          var pengisi = "pemohon";
          if (typeof jenis_input[i].pengisi !== "undefined") {
            pengisi = jenis_input[i].pengisi;
          }

          if (pengisi === "teknis") {
            continue;
          }
          
          if (jenis_input[i].type == "text") {
            var ComponentClass = Vue.extend(Text);
            var instance = new ComponentClass({
              propsData: {
                label: jenis_input[i].deskripsi,
                id: `${self.items.id}_${i}`,
                index: index,
                mandatory: jenis_input[i].mandatory,
                value: value,
              },
            });
            instance.$mount(); // pass nothing
            self.$refs.container.appendChild(instance.$el);
            index++;

            if (jenis_input[i].mandatory == "true") {
              self.mandatoryIndex++;
            }
          } else if (jenis_input[i].type == "number") {
            var ComponentClass = Vue.extend(Nomber);
            var instance = new ComponentClass({
              propsData: {
                label: jenis_input[i].deskripsi,
                id: `${self.items.id}_${i}`,
                index: index,
                mandatory: jenis_input[i].mandatory,
                value: value,
              },
            });
            instance.$mount(); // pass nothing
            self.$refs.container.appendChild(instance.$el);
            index++;

            if (jenis_input[i].mandatory == "true") {
              self.mandatoryIndex++;
            }
          } else if (jenis_input[i].type == "date") {
            var ComponentClass = Vue.extend(Tanggal);
            var instance = new ComponentClass({
              propsData: {
                label: jenis_input[i].deskripsi,
                id: `${self.items.id}_${i}`,
                index: index,
                mandatory: jenis_input[i].mandatory,
                value: value,
              },
            });
            instance.$mount(); // pass nothing
            self.$refs.container.appendChild(instance.$el);
            index++;

            if (jenis_input[i].mandatory == "true") {
              self.mandatoryIndex++;
            }
          } else if (jenis_input[i].type == "textarea") {
            var ComponentClass = Vue.extend(TextArea);
            var instance = new ComponentClass({
              propsData: {
                label: jenis_input[i].deskripsi,
                id: `${self.items.id}_${i}`,
                index: index,
                mandatory: jenis_input[i].mandatory,
                value: value,
              },
            });
            instance.$mount(); // pass nothing
            self.$refs.container.appendChild(instance.$el);
            index++;

            if (jenis_input[i].mandatory == "true") {
              self.mandatoryIndex++;
            }
          } else if (jenis_input[i].type == "radio") {
            var ComponentClass = Vue.extend(Radio);
            var instance = new ComponentClass({
              propsData: {
                label: jenis_input[i].deskripsi,
                id: `${self.items.id}_${i}`,
                optionsRadio: jenis_input[i].prop,
                index: index,
                mandatory: jenis_input[i].mandatory,
                valueData: value,
              },
            });
            instance.$mount(); // pass nothing
            self.$refs.container.appendChild(instance.$el);
            index++;

            if (jenis_input[i].mandatory == "true") {
              self.mandatoryIndex++;
            }
          } else if (jenis_input[i].type == "select") {
            var ComponentClass = Vue.extend(Select);
            var instance = new ComponentClass({
              propsData: {
                label: jenis_input[i].deskripsi,
                id: `${self.items.id}_${i}`,
                optionsSelect: jenis_input[i].prop,
                index: index,
                mandatory: jenis_input[i].mandatory,
                valueData: value,
              },
            });
            instance.$mount(); // pass nothing
            self.$refs.container.appendChild(instance.$el);
            index++;

            if (jenis_input[i].mandatory == "true") {
              self.mandatoryIndex++;
            }
          } else if (jenis_input[i].type == "checkbox") {
            var ComponentClass = Vue.extend(Checkbox);
            var instance = new ComponentClass({
              propsData: {
                label: jenis_input[i].deskripsi,
                id: `${self.items.id}_${i}`,
                optionsCheckbox: jenis_input[i].prop,
                index: index,
                mandatory: jenis_input[i].mandatory,
                valueData: value,
              },
            });
            instance.$mount(); // pass nothing
            self.$refs.container.appendChild(instance.$el);
            index++;

            if (jenis_input[i].mandatory == "true") {
              self.mandatoryIndex++;
            }
          } else if (jenis_input[i].type == "file") {
            var ComponentClass = Vue.extend(File);
            var instance = new ComponentClass({
              propsData: {
                label: jenis_input[i].deskripsi,
                id: `${self.items.id}_${i}`,
                index: indexFile,
                mandatory: jenis_input[i].mandatory,
                value: value,
              },
            });
            instance.$mount(); // pass nothing
            self.$refs.containerFile.appendChild(instance.$el);
            indexFile++;

            if (jenis_input[i].mandatory == "true") {
              self.mandatoryIndexFile++;
            }
          }
        }
        var param = self.RestribusiMultipleTampungan;
        
        var hasil = [];
        var indexnya = [];

        param.forEach(row => {
          self.form[row.index] = row.value;
          indexnya.push(row.index);
          row.value.forEach( (nilai, index) => {
            if (hasil[index] === undefined) {
              hasil[index] = {[row.column] : nilai, param : null}
            }else{
              hasil[index] = {
                ...hasil[index], [row.column] : nilai
              }
            }
          });
        });

        self.table_data = hasil;
        hasil.forEach((row, index) => {
          self.table_data[index].param = indexnya;
        });
        
      }
    });

    await axios({
      method: "GET",
      url: this.$config.url_api + "provinsi",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$cookies.get("landing"),
      },
    }).then(function (response) {
      if (response.data.status) {
        self.optionProvinsi = response.data.data;
      }
    });

    await self.fields.push({ key: "action", label: "Hapus" });
  },
};
</script>