<template>
  <div class="profile-page">
    <section class="section section-shaped section-lg my-0">
      <div class="shape shape-style-1 bg-gradient-success"></div>
      <div class="row row-grid justify-content-center">
        <div class="col-lg-8 text-center">
          <h2 class="text-white">Formulir Layanan Perizinan</h2>
        </div>
      </div>
    </section>

    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--4000 pt-50">
          <div class="row">
            <form-wizard :title="items.nama" 
                         subtitle="Isi formulir dengan dengan benar" 
                         backButtonText="Kembali" 
                         nextButtonText="Selanjutnya" 
                         finishButtonText="Selesai" 
                         @on-complete="onComplete" 
                         shape="circle" 
                         color="#2dce89" 
                         error-color="#ff4949">
              <tab-content title="Jenis Perizinan" :icon="iconFirst" :before-change="validateFirstStep">
                <form role="form" ref="ruleForm1">
                  <div class="col-md-12">
                    <b-form-group label="Tipe Permohonan">
                      <b-form-radio-group
                        id="tipePemohon"
                        v-model.trim="$v.tipePemohon.$model"
                        name="tipePemohon"
                      >
                        <b-form-radio :value="true">Perorangan</b-form-radio>
                        <b-form-radio :value="false">Perusahaan</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <div class="error" v-if="!$v.tipePemohon.required">Harus memilih salah satu</div>
                  </div>
                  
                </form>
              </tab-content>
              <tab-content title="Data Pemohon" :icon="iconSecond" :before-change="validateSecondStep">
                <h3>Data Pemohon</h3>
                <div class="col-md-12">
                  <base-input alternative :value="users.nama_lengkap" disabled helpText="Nama yang dimasukan adalah nama pemohon sesuai dengan KTP"></base-input>
                </div>
                <div class="col-md-12">
                  <base-input alternative :value="users.email" disabled helpText="Pastikan anda selalu mengecek email, karena pemberitahuan permohonan akan dikirimkan ke email anda"></base-input>
                </div>
                <div class="col-md-12">
                  <base-input alternative :value="users.nohp" disabled helpText="Gunakan nomor handphone yang aktif, karena pemberitahuan permohonan juga akan dikirimkan lewat sms"></base-input>
                </div>
                <div class="col-md-12">
                  <base-input alternative :value="users.nohpkuasa" disabled placeholder="No Hp Kuasa" helpText="(Opsional) Gunakan nomor handphone yang aktif, karena pemberitahuan permohonan juga akan dikirimkan lewat sms"></base-input>
                </div>
                <div class="col-md-12">
                  <base-input alternative :value="users.alamat+' ('+users.nama_desa+' '+users.nama_kecamatan+' '+users.nama_kabupaten+' '+users.nama_provinsi" disabled placeholder="Alamat Lengkap" helpText="Alamat Lengkap sesuai KTP Pemohon"></base-input>
                </div>
                <h3>Alamat Izin</h3>
                <b-row class="form-group">
                  <b-col md-12>
                    <b-form-group
                      >
                      <label for="">Provinsi *</label>
                      <v-select v-model="provinsiIzin"  label="nama" :options="optionProvinsi" :reduce="optionProvinsi => optionProvinsi.id" @input="createKabupaten" disabled></v-select>
                      <div class="error" v-if="provinsiIzin == 0">Harap Pilih Provinsi</div>
                      <div class="help-text" v-else>Provinsi berhasil dipilih</div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="form-group">
                  <b-col md-12>
                    <b-form-group
                      >
                      <label for="">Kabupaten *</label>
                      <v-select label="nama" :options="optionKabupaten" :reduce="optionKabupaten => optionKabupaten.id" v-model="kabupatenIzin" @input="createKecamatan" disabled></v-select>
                      <div class="error" v-if="kabupatenIzin == 0">Harap Pilih Kota/Kabupaten</div>
                      <div class="help-text" v-else>Kota/Kabupaten berhasil dipilih</div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="form-group">
                  <b-col md-12>
                    <b-form-group
                      >
                      <label for="">Kecamatan *</label>
                      <v-select label="nama" :options="optionKecamatan" :reduce="optionKecamatan => optionKecamatan.id" v-model="kecamatanIzin" @input="createDesa"></v-select>
                      <div class="error" v-if="kecamatanIzin == 0">Harap Pilih Kelurahan/Kecamatan</div>
                      <div class="help-text" v-else>Kelurahan/Kecamatan berhasil dipilih</div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="form-group">
                  <b-col md-12>
                    <b-form-group
                      >
                      <label for="">Desa *</label>
                      <v-select label="nama" :options="optionDesa" :reduce="optionDesa => optionDesa.id" v-model="desaIzin"></v-select>
                      <div class="error" v-if="desaIzin == 0">Harap Pilih Desa</div>
                      <div class="help-text" v-else>Desa berhasil dipilih</div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="form-group">
                  <b-col md-12>
                    <b-form-group
                      >
                      <label for="">Alamat Lengkap *</label>
                      <base-input alternative placeholder="Alamat izin" v-model.trim.lazy="$v.alamatIzin.$model"></base-input>
                      <div class="error" v-if="!$v.alamatIzin.required">Alamat lengkap harus terisi</div>
                      <div class="help-text" v-else>Alamat lengkap berhasil terisi</div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <span v-if="!$v.tipePemohon.$model">
                  <h3>Data Perusahaan</h3>
                  <b-row class="form-group">
                    <b-col md-12>
                      <b-form-group
                        >
                        <label for="">Nama Perusahaan *</label>
                        <base-input alternative v-model.trim.lazy="$v.perusahaan.nama.$model"></base-input>
                        <div class="error" v-if="!$v.perusahaan.nama.required">Nama harus terisi</div>
                        <div class="help-text" v-else>Nama sudah terisi</div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="form-group">
                    <b-col md-12>
                      <b-form-group
                        >
                        <label for="">Email Perusahaan *</label>
                        <base-input alternative v-model.trim.lazy="$v.perusahaan.email.$model"></base-input>
                        <div class="error" v-if="!$v.perusahaan.email.required">Email harus terisi</div>
                        <div class="help-text" v-else>Email sudah terisi</div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="form-group">
                    <b-col md-12>
                      <b-form-group
                        >
                        <label for="">No. NPWP *</label>
                        <base-input alternative v-model.trim.lazy="$v.perusahaan.npwp.$model"></base-input>
                        <div class="error" v-if="!$v.perusahaan.npwp.required">No. NPWP harus terisi</div>
                        <div class="help-text" v-else>No. NPWP sudah terisi</div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="form-group">
                    <b-col md-12>
                      <b-form-group
                        >
                        <label for="">Bidang Usaha *</label>
                        <base-input alternative v-model.trim.lazy="$v.perusahaan.bidang_usaha.$model"></base-input>
                        <div class="error" v-if="!$v.perusahaan.bidang_usaha.required">Bidang usaha harus terisi</div>
                        <div class="help-text" v-else>Bidang usaha sudah terisi</div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="form-group">
                    <b-col md-12>
                      <b-form-group
                        >
                        <label for="">No. Telp *</label>
                        <base-input alternative v-model.trim.lazy="$v.perusahaan.telp.$model"></base-input>
                        <div class="error" v-if="!$v.perusahaan.telp.required">No. Telp harus terisi</div>
                        <div class="help-text" v-else>No. Telp sudah terisi</div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="form-group">
                    <b-col md-12>
                      <b-form-group
                        >
                        <label for="">Alamat Perusahaan *</label>
                        <base-input alternative v-model.trim.lazy="$v.perusahaan.alamat.$model"></base-input>
                        <div class="error" v-if="!$v.perusahaan.alamat.required">Alamat harus terisi</div>
                        <div class="help-text" v-else>Alamat sudah terisi</div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </span>
              </tab-content>
              <tab-content title="Isi Formulir" :icon="iconThird" :before-change="validateThirdStep">
                <div id="resetAll" ref="container"></div>

                <!-- <div v-if="items.ismultiplerestribusi">

                  <hr>
                  
                  <p style="font-size:1rem;">
                    Harap menambahkan detail data dengan klik tombol dibawah. Anda dapat menambahkan lebih dari satu dengan cara yang sama.
                  </p>

                  <b-button v-b-modal.modal-prevent-closing variant="outline-success" size="md" class="mb-4">
                    Tambah Data
                  </b-button>

                  <b-modal
                    id="modal-prevent-closing"
                    ref="modal"
                    title="Form Detail (Anda dapat menambahkan data lebih dari satu)."
                    size="lg"
                    @show="resetModal"
                    @ok="handleOk"
                  >
                    <form ref="form" @submit.stop.prevent="handleSubmit">
                      <b-form-group
                        v-for="(todo, index) in RestribusiMultiple"
                        v-bind:key="index"
                        :label="`${todo.deskripsi}`"
                        :label-for="`form_detail_${index}`"
                        :invalid-feedback="`${todo.deskripsi} harus terisi.`"
                      >
                        <b-form-input
                          :id="`form_detail_${index}`"
                          type="number"
                          v-model="RestribusiMultiple[index].value"
                        ></b-form-input>
                      </b-form-group>
                    </form>
                  </b-modal>

                  <b-table
                    v-if="table_data.length" 
                    bordered
                    :items="table_data"
                    :fields="fields"
                    show-empty
                    responsive
                    :busy="isBusy"
                  >
                    <template #cell(action)="data">
                      <b-button variant="outline-danger" @click="deleteDetailData(data)">Hapus</b-button>
                    </template>

                    <template #table-busy>
                      <div class="text-center text-info my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>

                  </b-table>

                  <hr>
                </div> -->

              </tab-content>
              <tab-content title="Upload File" :icon="iconFourth" :before-change="validateFourthStep">
                <div id="resetAllFile" ref="containerFile"></div>
              </tab-content>
              <tab-content title="Ajukan" icon="fa fa-check">
                <h2>Ajukan Permohonan</h2>
                <h4>Syarat dan ketentuan pendaftaran permohonan</h4>
                <p>Dengan anda mengunjungi, mengakses, maupun memberikan informasi dengan mengisi informasi pada Layanan Perizinan Online (Selanjutnya disebut pemohon), Layanan Perizinan Online berhak menambah atau mengurangi peraturan maupun menambah syarat-syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dahulu.</p>
                <h4>Tanggung jawab pemohon</h4>
                <p>
                  <ol type="a">
                    <li>Data email dan no telepon yang digunakan untuk melakukan permohonan izin wajib menggunakan email dan no telepon pemohon dan tidak menggunakan no telepon kuasa.</li>
                    <li>Memeriksa kembali kebenaran seluruh data yang akan dimohonkan, petugas tidak bertanggung jawab atas kesalahan penulisan.</li>
                    <li>Memastikan informasi yang diberikan dapat dibuktikan keaslian maupun keabsahannya ketika melakukan permohonan izin. Jika pemohon terbukti melakukan pemalsuan dokumen maka pemohon sanksi sesuai hukum yang berlaku.</li>
                    <li>Memeriksa secara berkala status permohonan.</li>
                  </ol>
                  Dengan Menekan Tombol "SELESAI" dibawah anda telah menyetujui syarat dan ketentuan kami yang berlaku.
                </p>
              </tab-content>
            </form-wizard>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>
<style>
.vue-form-wizard {
  width: 100%;
}

.error {
  font-style: normal;
  color: maroon;
  font-size: 0.8rem;
}

.help-text {
  font-style: normal;
  color: #119eef;
  font-size: 0.8rem;
}
#successfull {
  color: green !important;
}
</style>
<script>
import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Vue from "vue";
Vue.use(VueFormWizard);
import EventBus from "../config/eventBus";

var Text = Vue.component("Text", {
  template:
    '<b-row class="form-group">\
                        <b-col md="12">\
                          <b-form-group\
                              :label="label"\
                              >\
                              <b-form-input type="text"  placeholder="Enter Text..." v-model="text" @input="check"></b-form-input>\
                              <div class="error" v-if="param"> {{ message }}</div>\
                            <div class="help-text" v-else>{{ message }}</div>\
                          </b-form-group>\
                        </b-col>\
                    </b-row>',
  props: ["label", "id", "index", "mandatory"],
  data: function () {
    return {
      text: "",
      param: true,
      message: "",
    };
  },
  created() {
    EventBus.$emit("addText", null, this.index, this.mandatory);
    if (this.mandatory == "true") {
      this.param = true;
      this.message = this.label + " harus Terisi";
    } else {
      this.param = false;
      this.message = "optional";
    }
  },
  methods: {
    check() {
      if (this.mandatory == "true") {
        this.param = true;
        this.message = this.label + " harus terisi";
      } else {
        this.param = false;
        this.message = "";
        this.message = "optional";
      }

      if (this.text != "") {
        this.param = false;
        this.message = this.label + " telah terisi";
        EventBus.$emit("addText", this.text, this.index, this.mandatory);
      } else {
        EventBus.$emit("addText", null, this.index, null);
      }
    },
  },
});

var Nomber = Vue.component("Nomber", {
  template:
    '<b-row class="form-group">\
                        <b-col md="12">\
                          <b-form-group\
                              :label="label"\
                              >\
                              <b-form-input type="number"  placeholder="Enter Text..." v-model="text" @input="check"></b-form-input>\
                              <div class="error" v-if="param"> {{ message }}</div>\
                            <div class="help-text" v-else>{{ message }}</div>\
                          </b-form-group>\
                        </b-col>\
                    </b-row>',
  props: ["label", "id", "index", "mandatory"],
  data: function () {
    return {
      text: "",
      param: true,
      message: "",
    };
  },
  created() {
    EventBus.$emit("addText", null, this.index, this.mandatory);
    if (this.mandatory == "true") {
      this.param = true;
      this.message = this.label + " harus Terisi";
    } else {
      this.param = false;
      this.message = "optional";
    }
  },
  methods: {
    check() {
      if (this.mandatory == "true") {
        this.param = true;
        this.message = this.label + " harus terisi";
      } else {
        this.param = false;
        this.message = "";
        this.message = "optional";
      }

      if (this.text != "") {
        this.param = false;
        this.message = this.label + " telah terisi";
        EventBus.$emit("addText", this.text, this.index, this.mandatory);
      } else {
        EventBus.$emit("addText", null, this.index, null);
      }
    },
  },
});

var Tanggal = Vue.component("Tanggal", {
  template:
    '<b-row class="form-group">\
                        <b-col md="12">\
                          <b-form-group\
                              :label="label"\
                              >\
                              <b-form-input type="date"  placeholder="Enter Text..." v-model="text" @input="check"></b-form-input>\
                              <div class="error" v-if="param"> {{ message }}</div>\
                            <div class="help-text" v-else>{{ message }}</div>\
                          </b-form-group>\
                        </b-col>\
                    </b-row>',
  props: ["label", "id", "index", "mandatory"],
  data: function () {
    return {
      text: "",
      param: true,
      message: "",
    };
  },
  created() {
    EventBus.$emit("addText", null, this.index, this.mandatory);
    if (this.mandatory == "true") {
      this.param = true;
      this.message = this.label + " harus Terisi";
    } else {
      this.param = false;
      this.message = "optional";
    }
  },
  methods: {
    check() {
      if (this.mandatory == "true") {
        this.param = true;
        this.message = this.label + " harus terisi";
      } else {
        this.param = false;
        this.message = "";
        this.message = "optional";
      }

      if (this.text != "") {
        this.param = false;
        this.message = this.label + " telah terisi";
        EventBus.$emit("addText", this.text, this.index, this.mandatory);
      } else {
        EventBus.$emit("addText", null, this.index, null);
      }
    },
  },
});

var TextArea = Vue.component("TextArea", {
  template:
    '<b-row class="form-group">\
                        <b-col md="12">\
                          <b-form-group\
                              :label="label"\
                              >\
                              <b-form-textarea :rows="5" placeholder="Enter Text.." v-model="text" @input="check"></b-form-textarea>\
                              <div class="error" v-if="param"> {{ message }}</div>\
                            <div class="help-text" v-else>{{ message }}</div>\
                          </b-form-group>\
                        </b-col>\
                    </b-row>',
  props: ["label", "id", "index", "mandatory"],
  data: function () {
    return {
      text: "",
      param: true,
      message: "",
    };
  },
  created() {
    EventBus.$emit("addText", null, this.index, this.mandatory);
    if (this.mandatory == "true") {
      this.param = true;
      this.message = this.label + " harus Terisi";
    } else {
      this.param = false;
      this.message = "optional";
    }
  },
  methods: {
    check() {
      if (this.mandatory == "true") {
        this.param = true;
        this.message = this.label + " harus terisi";
      } else {
        this.param = false;
        this.message = "";
        this.message = "optional";
      }

      if (this.text != "") {
        this.param = false;
        this.message = this.label + " telah terisi";
        EventBus.$emit("addText", this.text, this.index, this.mandatory);
      } else {
        EventBus.$emit("addText", null, this.index, null);
      }
    },
  },
});

var Radio = Vue.component("Radio", {
  template:
    '<b-row class="form-group">\
                        <b-col md="12">\
                          <b-form-group\
                              :label="label"\
                              >\
                              <b-form-radio-group\
                              :plain="true"\
                              class="ml-3"\
                              :options="optionsRadio"\
                              v-model="value"\
                              v-on:input="check"\
                              stacked>\
                            </b-form-radio-group>\
                            <div class="error" v-if="param"> {{ message }}</div>\
                            <div class="help-text" v-else>{{ message }}</div>\
                          </b-form-group>\
                        </b-col>\
                    </b-row>',
  props: ["label", "id", "optionsRadio", "index", "mandatory"],
  data: function () {
    return {
      value: null,
      param: true,
      message: "",
    };
  },
  created() {
    EventBus.$emit("addText", null, this.index, this.mandatory);
    if (this.mandatory == "true") {
      this.param = true;
      this.message = this.label + " Harus dipilih salah satu";
    } else {
      this.param = false;
      this.message = "";
      this.message = "optional";
    }
  },
  methods: {
    check() {
      if (this.mandatory == "true") {
        this.param = true;
        this.message = this.label + " Harus dipilih salah satu";
      } else {
        this.param = false;
        this.message = "optional";
      }

      if (this.value != null) {
        this.param = false;
        this.message = this.label + " telah dipilih";
        EventBus.$emit("addText", this.value, this.index, this.mandatory);
      } else {
        EventBus.$emit("addText", null, this.index, null);
      }
    },
  },
});

var Select = Vue.component("Select", {
  template:
    '<b-row class="form-group">\
                        <b-col md="12">\
                          <b-form-group\
                              :label="label"\
                              >\
                              <b-form-select :options="option" v-model="value" v-on:input="check"></b-form-select>\
                              <div class="error" v-if="param"> {{ message }}</div>\
                            <div class="help-text" v-else>{{ message }}</div>\
                          </b-form-group>\
                        </b-col>\
                    </b-row>',
  props: ["label", "id", "optionsSelect", "index", "mandatory"],
  data: function () {
    return {
      value: null,
      param: true,
      message: "",
      option: [{ value: null, text: "-- PILIH --" }],
    };
  },
  created() {
    EventBus.$emit("addText", null, this.index, this.mandatory);
    for (let i = 0; i < this.optionsSelect.length; i++) {
      this.option.push(this.optionsSelect[i]);
    }
    if (this.mandatory == "true") {
      this.param = true;
      this.message = this.label + " Harus dipilih salah satu";
    } else {
      this.param = false;
      this.message = "";
      this.message = "optional";
    }
  },
  methods: {
    check() {
      if (this.mandatory == "true") {
        this.param = true;
        this.message = this.label + " Harus dipilih salah satu";
      } else {
        this.param = false;
        this.message = "optional";
      }

      if (this.value != null) {
        this.param = false;
        this.message = this.label + " telah dipilih";
        EventBus.$emit("addText", this.value, this.index, this.mandatory);
      } else {
        EventBus.$emit("addText", null, this.index, null);
      }
    },
  },
});

var Checkbox = Vue.component("Checkbox", {
  template:
    '<b-row class="form-group">\
                        <b-col md="12">\
                          <b-form-group\
                              :label="label"\
                              >\
                              <b-form-checkbox-group\
                                :plain="true"\
                                class="ml-3"\
                                :options="optionsCheckbox"\
                                v-on:input="check"\
                                v-model="value"\
                                stacked\
                              ></b-form-checkbox-group>\
                              <div class="error" v-if="param"> {{ message }}</div>\
                            <div class="help-text" v-else>{{ message }}</div>\
                          </b-form-group>\
                        </b-col>\
                    </b-row>',
  props: ["label", "id", "optionsCheckbox", "index", "mandatory"],
  data: function () {
    return {
      value: [],
      param: true,
      message: "",
    };
  },
  created() {
    EventBus.$emit("addText", null, this.index, this.mandatory);
    if (this.mandatory == "true") {
      this.param = true;
      this.message = this.label + " Harus dipilih salah satu";
    } else {
      this.param = false;
      this.message = "optional";
    }
  },
  methods: {
    check() {
      if (this.mandatory == "true") {
        this.param = true;
        this.message = this.label + " Harus dipilih salah satu";
      } else {
        this.param = false;
        this.message = "optional";
      }

      if (this.value.length != 0) {
        this.param = false;
        this.message = this.label + " telah dipilih";
        EventBus.$emit("addText", this.value, this.index, this.mandatory);
      } else {
        EventBus.$emit("addText", null, this.index, this.mandatory);
      }
    },
  },
});

var File = Vue.component("File", {
  template:
    '<b-row class="form-group">\
                        <b-col md="8">\
                          <b-form-group :label="label" label-cols-sm="2">\
                            <b-form-file  :drop-placeholder="placeholder" :placeholder="placeholder" v-model="file" v-on:input="check" accept=".jpg, .png, .pdf, .jpeg, .doc"></b-form-file>\
                            <div class="error" v-if="param"> {{ message }}</div>\
                            <div class="help-text" v-else>{{ message }}</div>\
                          </b-form-group>\
                        </b-col>\
                        <b-col md="4">\
                          <b-button v-b-modal = "\'modal-lg\'+index" variant="outline-info"><i class="fa fa-archive"></i> ARSIP</b-button>\
                          <b-modal :id="\'modal-lg\'+index" size="lg" title="File Arsip" ok-only>\
                            <b-row>\
                              <b-col sm="6" class="my-1">\
                                <b-form-group\
                                  label="Filter"\
                                  label-cols-sm="5"\
                                  label-align-sm="right"\
                                  label-size="sm"\
                                  label-for="filterInput"\
                                  class="mb-0"\
                                >\
                                  <b-input-group size="sm">\
                                    <b-form-input\
                                      v-model="filter"\
                                      type="search"\
                                      id="filterInput"\
                                      placeholder="Type to Search"\
                                    ></b-form-input>\
                                    <b-input-group-append>\
                                      <b-button :disabled="!filter" @click="filter = null">Clear</b-button>\
                                    </b-input-group-append>\
                                  </b-input-group>\
                                </b-form-group>\
                              </b-col>\
                              <b-col sm="6">\
                                <b-pagination\
                                  v-model="currentPage"\
                                  :total-rows="totalRows"\
                                  :per-page="perPage"\
                                  align="fill"\
                                  size="sm"\
                                  class="my-0"\
                                ></b-pagination>\
                              </b-col>\
                              <b-table\
                              show-empty\
                              small\
                              stacked="md"\
                              :items="items"\
                              :fields="fields"\
                              :current-page="currentPage"\
                              :per-page="perPage"\
                              :filter="filter"\
                              @filtered="onFiltered"\
                            >\
                              <template v-slot:cell(actions)="row">\
                                <b-button size="sm" variant="outline-success" @click="info(row.item)" class="mr-1">\
                                  Gunakan\
                                </b-button>\
                              </template>\
                            </b-table>\
                            </b-row>\
                          </b-modal>\
                        </b-col>\
                    </b-row>',
  props: ["label", "id", "index", "mandatory", "filearsip"],
  data: function () {
    return {
      file: null,
      param: true,
      message: "",
      placeholder: "Upload File....",
      items: [],
      fields: [
        { key: "sumber", label: "Sumber" },
        { key: "deskripsi", label: "Nama/Deskripsi" },
        { key: "actions", label: "Actions" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      filter: null,
    };
  },
  created() {
    EventBus.$emit("addFile", null, this.index, this.mandatory);
    if (this.mandatory == "true") {
      this.param = true;
      this.message = this.label + " tidak boleh kosong";
    } else {
      this.param = false;
      this.message = "optional";
    }

    for (let i = 0; i < this.filearsip.length; i++) {
      this.items.push({
        sumber: this.filearsip[i].sumber,
        deskripsi: this.filearsip[i].deskripsi,
        file: this.filearsip[i].file,
      });
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    info(item) {
      this.message = "Telah diisi dari file arsip";
      this.placeholder = item.deskripsi;
      EventBus.$emit("addFile", item.file, this.index, this.mandatory);
      this.$bvModal.hide("modal-lg" + this.index);
      this.param = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    check() {
      if (this.mandatory == "true") {
        this.param = true;
        this.message = this.label + " tidak boleh kosong";
      } else {
        this.param = false;
        this.message = "optional";
      }

      if (this.file.name != "") {
        this.param = false;
        this.message = this.label + " telah terisi";
        EventBus.$emit("addFile", this.file, this.index, this.mandatory);
      } else {
        EventBus.$emit("addFile", null, this.index, this.mandatory);
      }
    },
  },
});

import { required, minLength } from "vuelidate/lib/validators";
import axios from "axios";
import $ from "jquery";
export default {
  data() {
    return {
      users: [],
      filearsip: [],
      items: [],
      input: [],
      form: [],
      formFile: [],
      mandatoryParam: [],
      mandatoryIndex: 0,
      mandatoryParamFile: [],
      mandatoryIndexFile: 0,
      iconFirst: "fa fa-question",
      iconSecond: "fa fa-id-badge",
      iconThird: "fa fa-file-text",
      iconFourth: "fa fa-files-o",
      buatBaru: true,
      tipePemohon: true,
      optionProvinsi: [],
      optionKabupaten: [],
      optionKecamatan: [],
      optionDesa: [],
      provinsiIzin: "35",
      kabupatenIzin: "3514",
      kecamatanIzin: 0,
      desaIzin: 0,
      alamatIzin: "",
      perusahaan: {
        nama: null,
        email: null,
        npwp: null,
        bidang_usaha: null,
        telp: null,
        alamat: null,
      },
      table_data: [],
      fields: [],
      RestribusiMultiple: [],
      isBusy: false,
    };
  },
  validations: {
    // buatBaru: {
    //   required,
    // },
    tipePemohon: {
      required,
    },
    name: {
      required,
      minLength: minLength(4),
    },
    alamatIzin: {
      required,
    },
    perusahaan: {
      nama: { required },
      email: { required },
      npwp: { required },
      bidang_usaha: { required },
      telp: { required },
      alamat: { required },
    },
  },
  mounted() {
    EventBus.$on("addText", (text, index, mandatory) => {
      this.form[index] = text;
      this.mandatoryParam[index] = mandatory;
    });

    EventBus.$on("addFile", (file, index, mandatory) => {
      this.formFile[index] = file;
      this.mandatoryParamFile[index] = mandatory;
    });
  },
  async created() {
    this.createKabupaten();
    this.createKecamatan();
    var self = this;
    var kategoriId = this.$router.history.current.params.kategoriId;
    await axios({
      method: "GET",
      url: this.$config.url_api + "izin/filearsip",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$cookies.get("landing"),
      },
    }).then(function (response) {
      if (response.data.status == "ok") {
        self.filearsip = response.data.data;
      }
    });

    await axios({
      method: "GET",
      url: this.$config.url_api + "setting/users/biodata",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$cookies.get("landing"),
      },
    }).then(function (response) {
      if (response.data.status) {
        self.users = response.data.data;
      }
    });

    axios({
      method: "GET",
      url: this.$config.url_api + "master/kategori/edit/" + kategoriId,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$cookies.get("landing"),
      },
    }).then(function (response) {
      if (response.data.status) {
        self.items = response.data.data;

        var rumus = response.data.rumus;

        var jenis_input = JSON.parse(response.data.data.jenis_input);

        let index = 0;
        let indexFile = 0;
        for (let i = 0; i < jenis_input.length; i++) {
          var lanjut = false;
          // if (response.data.data.ismultiplerestribusi) {
          //   for (let j = 0; j < rumus.length; j++) {
          //     if (jenis_input[i].deskripsi == rumus[j].text) {
          //       self.RestribusiMultiple.push({
          //         deskripsi: jenis_input[i].deskripsi,
          //         column: jenis_input[i].column,
          //         value: 0,
          //         index: index,
          //       });

          //       self.fields.push({
          //         key: jenis_input[i].column,
          //         label: jenis_input[i].deskripsi,
          //       });

          //       lanjut = true;
          //       break;
          //     }
          //   }
          // }

          if (lanjut) {
            self.form.push([]);
            index++;
            continue;
          }

          var pengisi = "pemohon";
          if (typeof jenis_input[i].pengisi !== "undefined") {
            pengisi = jenis_input[i].pengisi;
          }

          if (pengisi === "teknis") {
            continue;
          }

          if (jenis_input[i].type == "text") {
            var ComponentClass = Vue.extend(Text);
            var instance = new ComponentClass({
              propsData: {
                label: jenis_input[i].deskripsi,
                id: jenis_input[i].label.replace(/[^A-Z0-9]/gi, "_"),
                index: index,
                mandatory: jenis_input[i].mandatory,
              },
            });
            instance.$mount(); // pass nothing
            self.$refs.container.appendChild(instance.$el);
            index++;

            if (jenis_input[i].mandatory == "true") {
              self.mandatoryIndex++;
            }
          } else if (jenis_input[i].type == "number") {
            var ComponentClass = Vue.extend(Nomber);
            var instance = new ComponentClass({
              propsData: {
                label: jenis_input[i].deskripsi,
                id: jenis_input[i].label.replace(/[^A-Z0-9]/gi, "_"),
                index: index,
                mandatory: jenis_input[i].mandatory,
              },
            });
            instance.$mount(); // pass nothing
            self.$refs.container.appendChild(instance.$el);
            index++;

            if (jenis_input[i].mandatory == "true") {
              self.mandatoryIndex++;
            }
          } else if (jenis_input[i].type == "date") {
            var ComponentClass = Vue.extend(Tanggal);
            var instance = new ComponentClass({
              propsData: {
                label: jenis_input[i].deskripsi,
                id: jenis_input[i].label.replace(/[^A-Z0-9]/gi, "_"),
                index: index,
                mandatory: jenis_input[i].mandatory,
              },
            });
            instance.$mount(); // pass nothing
            self.$refs.container.appendChild(instance.$el);
            index++;

            if (jenis_input[i].mandatory == "true") {
              self.mandatoryIndex++;
            }
          } else if (jenis_input[i].type == "textarea") {
            var ComponentClass = Vue.extend(TextArea);
            var instance = new ComponentClass({
              propsData: {
                label: jenis_input[i].deskripsi,
                id: jenis_input[i].label.replace(/[^A-Z0-9]/gi, "_"),
                index: index,
                mandatory: jenis_input[i].mandatory,
              },
            });
            instance.$mount(); // pass nothing
            self.$refs.container.appendChild(instance.$el);
            index++;

            if (jenis_input[i].mandatory == "true") {
              self.mandatoryIndex++;
            }
          } else if (jenis_input[i].type == "radio") {
            var ComponentClass = Vue.extend(Radio);
            var instance = new ComponentClass({
              propsData: {
                label: jenis_input[i].deskripsi,
                id: jenis_input[i].label.replace(/[^A-Z0-9]/gi, "_"),
                optionsRadio: jenis_input[i].prop,
                index: index,
                mandatory: jenis_input[i].mandatory,
              },
            });
            instance.$mount(); // pass nothing
            self.$refs.container.appendChild(instance.$el);
            index++;

            if (jenis_input[i].mandatory == "true") {
              self.mandatoryIndex++;
            }
          } else if (jenis_input[i].type == "select") {
            var ComponentClass = Vue.extend(Select);
            var instance = new ComponentClass({
              propsData: {
                label: jenis_input[i].deskripsi,
                id: jenis_input[i].label.replace(/[^A-Z0-9]/gi, "_"),
                optionsSelect: jenis_input[i].prop,
                index: index,
                mandatory: jenis_input[i].mandatory,
              },
            });
            instance.$mount(); // pass nothing
            self.$refs.container.appendChild(instance.$el);
            index++;

            if (jenis_input[i].mandatory == "true") {
              self.mandatoryIndex++;
            }
          } else if (jenis_input[i].type == "checkbox") {
            var ComponentClass = Vue.extend(Checkbox);
            var instance = new ComponentClass({
              propsData: {
                label: jenis_input[i].deskripsi,
                id: jenis_input[i].label.replace(/[^A-Z0-9]/gi, "_"),
                optionsCheckbox: jenis_input[i].prop,
                index: index,
                mandatory: jenis_input[i].mandatory,
              },
            });
            instance.$mount(); // pass nothing
            self.$refs.container.appendChild(instance.$el);
            index++;

            if (jenis_input[i].mandatory == "true") {
              self.mandatoryIndex++;
            }
          } else if (jenis_input[i].type == "file") {
            var ComponentClass = Vue.extend(File);
            var instance = new ComponentClass({
              propsData: {
                label: jenis_input[i].deskripsi,
                id: jenis_input[i].label.replace(/[^A-Z0-9]/gi, "_"),
                index: indexFile,
                mandatory: jenis_input[i].mandatory,
                filearsip: self.filearsip,
              },
            });
            instance.$mount(); // pass nothing
            self.$refs.containerFile.appendChild(instance.$el);
            indexFile++;

            if (jenis_input[i].mandatory == "true") {
              self.mandatoryIndexFile++;
            }
          }
        }
      }
    });

    await axios({
      method: "GET",
      url: this.$config.url_api + "provinsi",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$cookies.get("landing"),
      },
    }).then(function (response) {
      if (response.data.status) {
        self.optionProvinsi = response.data.data;
      }
    });

    await self.fields.push({ key: "action", label: "Hapus" });
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    deleteDetailData(data) {
      this.table_data.splice(data.index, 1);
      var param = data.item.param;
      param.forEach(row => {
        this.form[row].splice(data.index, 1);
      });
    },
    resetModal() {
      var items = this.RestribusiMultiple;
      for (let i = 0; i < items.length; i++) {
        this.RestribusiMultiple[i].value = 0;
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      var items = this.RestribusiMultiple;

      var obj = {};
      var param = [];
      for (let i = 0; i < items.length; i++) {
        this.form[items[i].index].push(items[i].value);
        param.push(items[i].index);
        obj[items[i].column] = items[i].value;
      }
      obj = { ...obj, param: param };
      this.table_data.push(obj);

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    createKabupaten() {
      var self = this;

      axios({
        method: "GET",
        url: this.$config.url_api + "kabupaten/" + this.provinsiIzin,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
      }).then(function (response) {
        if (response.data.status) {
          self.optionKabupaten = response.data.data;
        }
      });
    },
    createKecamatan() {
      var self = this;

      axios({
        method: "GET",
        url: this.$config.url_api + "kecamatan/" + this.kabupatenIzin,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
      }).then(function (response) {
        if (response.data.status) {
          self.optionKecamatan = response.data.data;
        }
      });
    },
    createDesa() {
      var self = this;

      axios({
        method: "GET",
        url: this.$config.url_api + "desa/" + this.kecamatanIzin,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
      }).then(function (response) {
        if (response.data.status) {
          self.optionDesa = response.data.data;
        }
      });
    },
    onComplete: function () {
      $(".wizard-btn").prop("disabled", true);
      $(".wizard-footer-right .wizard-btn").text("loading...");
      var kategoriId = this.$router.history.current.params.kategoriId;
      var form = this.form;
      var file = this.formFile;
      var buatBaru = this.buatBaru;
      var tipePemohon = this.tipePemohon;
      var desa_id = this.desaIzin;
      var alamat_lokasi = this.alamatIzin;
      var perusahaan_nama = this.perusahaan.nama;
      var perusahaan_email = this.perusahaan.email;
      var perusahaan_npwp = this.perusahaan.npwp;
      var perusahaan_bidang_usaha = this.perusahaan.bidang_usaha;
      var perusahaan_telp = this.perusahaan.telp;
      var perusahaan_alamat = this.perusahaan.alamat;
      var id = this.generateId();
      const dataku = new FormData();
      var self = this;

      dataku.append("kategoriId", kategoriId);
      dataku.append("id", id);
      for (let i = 0; i < form.length; i++) {
        dataku.append("form[" + i + "]", form[i]);
      }

      for (let i = 0; i < file.length; i++) {
        dataku.append("file[" + i + "]", file[i]);
      }
      dataku.append("buatbaru", buatBaru);
      dataku.append("tipepemohon", tipePemohon);
      dataku.append("desa_id", desa_id);
      dataku.append("alamat_lokasi", alamat_lokasi);

      dataku.append("perusahaan_nama", perusahaan_nama);
      dataku.append("perusahaan_email", perusahaan_email);
      dataku.append("perusahaan_npwp", perusahaan_npwp);
      dataku.append("perusahaan_bidang_usaha", perusahaan_bidang_usaha);
      dataku.append("perusahaan_telp", perusahaan_telp);
      dataku.append("perusahaan_alamat", perusahaan_alamat);

      // console.log(self.table_data);
      $.ajax({
        url: self.$config.url_api + "izin/store",
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
        success: function (response) {
          if (response.status == "ok") {
            self.$router.push({
              path: "../permohonansaya",
            });
          }
        },
      });
    },
    validateFirstStep() {
      // return true;
      this.$v.$touch();
      if (this.$v.tipePemohon.$invalid) {
        this.iconFirst = "fa fa-times";
        return false;
      } else {
        this.iconFirst = "fa fa-check";
        return true;
      }
    },
    validateSecondStep() {
      // return true;
      this.$v.$touch();
      if (
        this.provinsiIzin == 0 ||
        this.kabupatenIzin == 0 ||
        this.kecamatanIzin == 0 ||
        this.desaIzin == 0
      ) {
        this.iconFirst = "fa fa-times";
        return false;
      } else {
        this.iconFirst = "fa fa-check";
        return true;
      }
    },
    validateThirdStep() {
      var form = this.form;
      var mandatory = this.mandatoryParam;
      let param = 0;
      for (let i = 0; i < form.length; i++) {
        if (mandatory[i] == "true" && form[i] != null) {
          param++;
        }
      }

      if (this.mandatoryIndex != param) {
        this.iconFirst = "fa fa-times";
        alert("Data tidak valid, harap cek kembali!");
        return false;
      } else {
        this.iconFirst = "fa fa-check";
        // if (this.items.ismultiplerestribusi) {
        //   if (this.table_data.length) {
        //     return true;
        //   } else {
        //     alert("Data tidak valid, harap tambahkan detail data!");
        //     return false;
        //   }
        // } else {
        //   return true;
        // }
        return true;
      }
    },
    validateFourthStep() {
      var form = this.formFile;
      var mandatory = this.mandatoryParamFile;
      let param = 0;
      for (let i = 0; i < form.length; i++) {
        if (mandatory[i] == "true" && form[i] != null) {
          param++;
        }
      }

      if (this.mandatoryIndexFile != param) {
        this.iconFirst = "fa fa-times";
        alert("Data tidak valid, harap cek kembali!");
        return false;
      } else {
        this.iconFirst = "fa fa-check";
        return true;
      }
    },
  },
};
</script>