<template>
  <div class="profile-page">
    <section class="section section-shaped section-lg my-0">
      <div class="shape shape-style-1 bg-gradient-success"></div>
      <div class="row row-grid justify-content-center">
        <div class="col-lg-8 text-center">
          <h2 class="text-white">Daftar Layanan Perizinan</h2>
        </div>
      </div>
    </section>

    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--4000">
          <b-card v-for="item in items" :key="item.id" no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                block
                v-b-toggle="['collapse-' + item.id]"
                variant="info"
                class="text-left"
              >
                <img
                  v-if="item.icon != ''"
                  :src="$config.images_url + 'icon_perizinan/' + item.icon"
                  class="pr-3 img-responsive"
                  style="width: 10%"
                />
                <img
                  v-else
                  src="../assets/image/no-icon.png"
                  class="pr-3 img-responsive"
                  style="width: 10%"
                />
                {{ item.nama }} ({{ item.code }})
              </b-button>
            </b-card-header>
            <b-collapse
              :id="'collapse-' + item.id"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <li
                  v-for="kat in kategori"
                  v-if="kat.perizinan_id == item.id"
                  :key="kat.id"
                  class="
                    list-group-item list-group-item-action
                    d-flex
                    align-items-center
                  "
                  v-b-toggle="'collapse-a collapse-b'"
                  @click="kategoriPerizinan(kat.id, kat.nama)"
                >
                  {{ kat.nama }}
                </li>
              </b-card-body>
            </b-collapse>
          </b-card>
        </card>
      </div>
    </section>

    <div>
      <modal
        :show.sync="modal1"
        modalClasses="modal-dialog-custom"
        headerClasses="modal-header-custom"
      >
        <template>
          <h2 slot="header" class="modal-title" id="modal-title-default">
            {{ nama }}
          </h2>
          <div class="tabsuccess">
            <div>
              <br />
              <tabs fill class="flex-column flex-md-row" v-if="labels.length">
                <card shadow slot-scope="{ activeTabIndex }">
                  <tab-pane key="tab1">
                    <template slot="title">
                      <i class="ni ni-collection mr-2"></i>FORMULIR
                    </template>

                    <ul class="list-group list-group-flush list my--3">
                      <li
                        class="list-group-item px-0"
                        v-for="(label, index) in labels"
                        v-if="label.type !== 'file' && label.pengisi === 'pemohon'"
                        v-bind:key="index"
                      >
                        <div class="row align-items-center">
                          <!-- <div class="col-auto">
                           
                          </div> -->
                          <div class="col ml--2">
                            <h4 class="mb-0">
                              <a href="#">{{ label.deskripsi }}</a>
                            </h4>
                          </div>
                          <div
                            class="col-auto"
                            style="width: 100%; max-width: 100%"
                          >
                            <button
                              type="button"
                              class="btn btn-sm btn-danger"
                              v-if="label.mandatory === 'true'"
                              style="float: right"
                            >
                              <i class="fa fa-info"></i> Wajib
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm btn-primary"
                              v-else
                              style="float: right"
                            >
                              <i class="fa fa-info"></i> Tentatif
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </tab-pane>

                  <tab-pane key="tab2">
                    <template slot="title">
                      <i class="ni ni-briefcase-24 mr-2"></i>SYARAT
                    </template>

                    <ul class="list-group list-group-flush list my--3">
                      <li
                        class="list-group-item px-0"
                        v-for="(label, index) in labels"
                        v-bind:key="index"
                        v-if="label.type == 'file' && label.pengisi === 'pemohon'"
                      >
                        <div class="row align-items-center">
                          <!-- <div class="col-auto">
                           
                          </div> -->
                          <div class="col ml--2">
                            <h4 class="mb-0">
                              <a href="#!">{{ label.deskripsi }}</a>
                            </h4>
                          </div>
                          <div
                            class="col-auto"
                            style="width: 100%; max-width: 100%"
                          >
                            <button
                              type="button"
                              class="btn btn-sm btn-danger"
                              v-if="label.mandatory === 'true'"
                              style="float: right"
                            >
                              <i class="fa fa-info"></i> Wajib
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm btn-primary"
                              v-else
                              style="float: right"
                            >
                              <i class="fa fa-info"></i> Tentatif
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </tab-pane>
                </card>
              </tabs>
              <br />
              <!-- <p>* Estimasi izin selesai {{ hari }} hari setelah berkas permohonanan dinyatakan lengkap dan benar</p> -->
            </div>
          </div>
          <template slot="footer" style="text-align: center">
            <button
              type="submit"
              class="btn btn-success btn-lg btn-block"
              @click="ajukan()"
            >
              <i class="fa fa-paper-plane"></i> Syarat Lengkap & Ajukan
            </button>
          </template>
        </template>
      </modal>
    </div>
  </div>
</template>
<style>
.modal-header-custom {
  border-bottom: none !important;
}
.modal-dialog-custom {
  max-width: 100% !important;
  margin: 100px 15px 0px 15px !important;
}

.modal-dialog-custom .modal-content {
  min-height: 90vh;
  padding-left: 10%;
}

.tabsuccess {
  padding-right: 10%;
}
.tabscolor {
  padding: 10% !important;
}
</style>
<script>
import Modal from "@/components/Modal.vue";
import axios from "axios";
import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";
export default {
  components: {
    Modal,
    Tabs,
    TabPane,
  },
  data() {
    return {
      modal1: false,
      tabIndex: 1,
      items: [],
      kategori: [],
      labels: [],
      id: 0,
      kategoriId: 0,
      nama: "",
      hari: 0,
    };
  },
  created() {
    var self = this;
    axios({
      method: "GET",
      url: this.$config.url_api + "master/perizinan",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$cookies.get("landing"),
      },
    }).then(function (response) {
      if (response.data.status) {
        self.items = response.data.data;
        self.kategori = response.data.kategori;
      }
    });
  },
  methods: {
    async kategoriPerizinan(id, nama) {
      this.kategoriId = id;
      var self = this;
      self.labels.splice(0, self.labels.length);
      await axios({
        method: "GET",
        url: this.$config.url_api + "master/kategori/edit/" + id,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
      }).then(function (response) {
        if (response.data.status == "ok") {
          self.labels = JSON.parse(response.data.data.jenis_input);
          if (self.labels != null) {
            self.modal1 = true;
            self.id = id;
            self.nama = nama;
          } else {
            alert(
              "Mohon maaf untuk saat ini anda belum dapat mengajukan perizinan ini secara online"
            );
            self.labels = [];
          }
        }
      });
    },
    ajukan() {
      if (this.kategoriId == 0) {
        alert("Harap Pilih Jenis Perizinan Terlebih Dahulu.");
      } else {
        if (this.$cookies.isKey("landing")) {
          var self = this;
          axios({
            method: "GET",
            url: this.$config.url_api + "setting/users/checkuser/"+ this.kategoriId,
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + this.$cookies.get("landing"),
            },
          }).then(function (response) {
            if (response.data.verif_status != 3) {
              window.location.href = self.$config.base_url + "profile";
            } else if (response.data.status == 'gagal') {
              alert(response.data.message);
            } else {
              window.location.href =
                self.$config.base_url + "form_izin/" + self.kategoriId;
            }
          });
        } else {
          this.$cookies.remove("kategoriId");
          this.$cookies.set("kategoriId", this.kategoriId);
          this.$router.push("login");
        }
      }
    },
  },
};
</script>