<template>
  <div class="profile-page">
    <section
      class="section section-shaped section-lg my-0"
      style="margin-bottom: -4rem !important"
    >
      <div class="shape shape-style-1 bg-gradient-success"></div>
    </section>

    <section class="section section-skew" style="padding-top: 10rem">
      <div class="container">
        <card shadow class="card-profile mt--300">
          <b-form v-on:submit.prevent="store">
            <b-row>
              <b-col cols="5">
                <img :src="urlImage" style="width: 100%" class="mb-2" />
                <b-form-file
                  v-model="picture"
                  :state="Boolean(picture)"
                  @change="onFileChange"
                  accept="image/jpeg, image/png, image/jpg"
                  placeholder="Upload Foto disini kemudian klik simpan"
                  drop-placeholder="Drop file here..."
                ></b-form-file>
                <b-alert show="show" :variant="variant_status" class="mt-2"
                  ><i class="fa fa-bell" aria-hidden="true"></i>
                  {{ verif_alasan }}</b-alert
                >
                <b-alert show="show" variant="info" class="mt-2">
                  <i class="fa fa-bell" aria-hidden="true"></i> Untuk mendapatkan notifikasi melalui telegram anda dapat menyambungkan telegram anda 
                  <a href="/telegram" class="text-dark"> di sini </a>.
                </b-alert>
              </b-col>
              <b-col cols="7">
                <h3 style="font-weight: bold">IDENTITAS</h3>
                <b-row>
                  <b-col>
                    <b-form-group label="Jenis User *" label-for="Jenis user">
                      <div class="position-relative form-group">
                        <div>
                          <div
                            class="
                              custom-radio custom-control custom-control-inline
                            "
                          >
                            <input
                              type="radio"
                              id="jenis_identitas2"
                              name="jenis_identitas"
                              class="custom-control-input"
                              value="KTP"
                              v-model="jenis_identitas"
                            />
                            <label
                              class="custom-control-label"
                              for="jenis_identitas2"
                              >KTP
                            </label>
                          </div>
                          <div
                            class="
                              custom-radio custom-control custom-control-inline
                            "
                          >
                            <input
                              type="radio"
                              id="jenis_identitas1"
                              name="jenis_identitas"
                              class="custom-control-input"
                              value="PASSPORT"
                              v-model="jenis_identitas"
                            />
                            <label
                              class="custom-control-label"
                              for="jenis_identitas1"
                              >PASSPORT
                            </label>
                          </div>
                        </div>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="form-group">
                  <b-col>
                    <b-form-group
                      :label="'No ' + jenis_identitas + ' *'"
                      label-for="noktp"
                      validated
                    >
                      <b-form-input
                        id="noktp"
                        type="number"
                        autocomplete="noktp"
                        v-model="noktp"
                        placeholder="Enter Text..."
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="form-group">
                  <b-col>
                    <b-form-group
                      label="Nama Lengkap *"
                      label-for="nama_lengkap"
                      validated
                    >
                      <b-form-input
                        id="nama_lengkap"
                        type="text"
                        autocomplete="nama_lengkap"
                        v-model="nama_lengkap"
                        placeholder="Enter Text..."
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="form-group">
                  <b-col md-12>
                    <b-form-group label="Provinsi *" label-for="Provinsi">
                      <v-select
                        label="nama"
                        :options="optionProvinsi"
                        :reduce="(optionProvinsi) => optionProvinsi.id"
                        v-model="provinsi_id"
                        @input="createKabupaten"
                        required
                      ></v-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="form-group">
                  <b-col md-12>
                    <b-form-group label="Kabupaten *" label-for="kabupaten">
                      <v-select
                        label="nama"
                        :options="optionKabupaten"
                        :reduce="(optionKabupaten) => optionKabupaten.id"
                        v-model="kabupaten_id"
                        @input="createKecamatan"
                        required
                      ></v-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="form-group">
                  <b-col md-12>
                    <b-form-group label="Kecamatan *" label-for="Kecamatan">
                      <v-select
                        label="nama"
                        :options="optionKecamatan"
                        :reduce="(optionKecamatan) => optionKecamatan.id"
                        v-model="kecamatan_id"
                        @input="createDesa"
                        required
                      ></v-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="form-group">
                  <b-col md-12>
                    <b-form-group label="Desa *" label-for="desa">
                      <v-select
                        label="nama"
                        :options="optionDesa"
                        :reduce="(optionDesa) => optionDesa.id"
                        v-model="desa_id"
                        required
                      ></v-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="form-group">
                  <b-col>
                    <b-form-group label="Alamat" label-for="Alamat" validated>
                      <b-form-textarea
                        id="alamat"
                        :rows="9"
                        v-model="alamat"
                        placeholder="Enter Text.."
                        required
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="form-group">
                  <b-col sm="7">
                    <b-form-group label="No Hp" label-for="nohp" validated>
                      <b-form-input
                        id="nohp"
                        type="number"
                        autocomplete="nohp"
                        v-model="nohp"
                        placeholder="Enter Text..."
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="form-group">
                  <b-col>
                    <b-form-group
                      label="No Hp 2/No HP Kuasa"
                      label-for="nohpkuasa"
                      validated
                    >
                      <b-form-input
                        id="nohpkuasa"
                        type="number"
                        autocomplete="nohpkuasa"
                        v-model="nohpkuasa"
                        placeholder="Enter Text..."
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
                <h3 style="font-weight: bold">PASSWORD</h3>
                <b-row class="form-group">
                  <b-col>
                    <b-form-group
                      label="Email"
                      label-for="email"
                      validated
                      description="Jika anda mengganti email harap verifikasi email ulang"
                    >
                      <b-form-input
                        type="email"
                        autocomplete="nope"
                        v-model="emailUser"
                        placeholder="Enter Text..."
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="form-group">
                  <b-col>
                    <b-form-group
                      label="Password"
                      label-for="password"
                      validated
                      description="Isi password apabila ingin mengganti password"
                    >
                      <b-form-input
                        type="password"
                        v-model="password"
                        autocomplete="new-password"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <div slot="footer" style="text-align: center">
              <b-alert :show="error" variant="danger">
                <h4 class="alert-heading">Peringatan!</h4>
                <ul id="example-1">
                  <li v-for="(item, index) in items" v-bind:key="index">
                    {{ item[0] }}
                  </li>
                </ul>
              </b-alert>
              <b-button type="submit" size="lg" variant="success" block
                ><i class="fa fa-dot-circle-o"></i> Simpan</b-button
              >
            </div>
          </b-form>
        </card>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";
export default {
  data() {
    return {
      img: "no-images.jpg",
      show: true,
      error: false,
      items: [],
      noktp: "",
      nama_lengkap: "",
      alamat: "",
      nohp: "",
      nohpkuasa: "",
      emailUser: "",
      password: "",
      picture: null,
      status_admin: "",
      opd: "",
      role: "",
      desa_id: null,
      kecamatan_id: null,
      kabupaten_id: null,
      provinsi_id: null,
      jenis_identitas: "KTP",
      optionProvinsi: [],
      optionKabupaten: [],
      optionKecamatan: [],
      optionDesa: [],
      verif_alasan: "",
      urlImage: "",
      variant_status: "warning",
    };
  },
  created() {
    var self = this;

    axios({
      method: "GET",
      url: this.$config.url_api + "provinsi",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$cookies.get("landing"),
      },
    }).then(function (response) {
      if (response.data.status) {
        self.optionProvinsi = response.data.data;
      }
    });

    axios({
      method: "GET",
      url: this.$config.url_api + "setting/users/biodata",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$cookies.get("landing"),
      },
    }).then(function (response) {
      if (response.data.status == "ok") {
        self.provinsi_id = response.data.data.provinsi_id;

        self.createKabupaten();
        self.kabupaten_id = response.data.data.kabupaten_id;
        self.createKecamatan();
        self.kecamatan_id = response.data.data.kecamatan_id;
        self.createDesa();
        self.desa_id = response.data.data.desa_id;

        if (response.data.data.foto == null) {
          self.urlImage = self.$config.base_url + "img/no_profile_image.jpeg";
        } else {
          self.urlImage =
            self.$config.url_api + "fotoprofil/" + response.data.data.foto;
        }
        self.noktp = response.data.data.noktp;
        self.nama_lengkap = response.data.data.nama_lengkap;
        self.alamat = response.data.data.alamat;
        self.nohp = response.data.data.nohp;
        self.nohpkuasa = response.data.data.nohpkuasa;
        self.emailUser = response.data.data.email;
        var verif_status = response.data.data.verif_status;
        self.variant_status = "warning";
        if (verif_status == 0) {
          self.verif_alasan =
            "Akun belum terverifikasi, Harap mengubah foto sesuai dengan contoh gambar diatas (Berfoto selfi serta membawa KTP) dan melengkapi seluruh data diri";
        } else if (verif_status == 1) {
          self.verif_alasan = "Sedang menunggu proses verifikasi.";
        } else if (verif_status == 2) {
          self.verif_alasan = response.data.data.verif_alasan;
        } else if (verif_status == 3) {
          self.variant_status = "success";
          self.verif_alasan = "Akun anda sudah valid.";
        }
        // self.show= false;
      }
    });

    self.passwordUser = null;
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.urlImage = URL.createObjectURL(file);
    },
    createKabupaten() {
      var self = this;
      this.kabupaten_id = null;
      this.kecamatan_id = null;
      this.desa_id = null;
      axios({
        method: "GET",
        url: this.$config.url_api + "kabupaten/" + this.provinsi_id,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
      }).then(function (response) {
        if (response.data.status) {
          self.optionKabupaten = response.data.data;
        }
      });
    },
    createKecamatan() {
      var self = this;
      this.kecamatan_id = null;
      this.desa_id = null;
      axios({
        method: "GET",
        url: this.$config.url_api + "kecamatan/" + this.kabupaten_id,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
      }).then(function (response) {
        if (response.data.status) {
          self.optionKecamatan = response.data.data;
        }
      });
    },
    createDesa() {
      var self = this;
      this.desa_id = null;
      axios({
        method: "GET",
        url: this.$config.url_api + "desa/" + this.kecamatan_id,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
      }).then(function (response) {
        if (response.data.status) {
          self.optionDesa = response.data.data;
        }
      });
    },
    store: function () {
      var jenis_identitas = this.jenis_identitas;
      var noktp = this.noktp;
      var nama_lengkap = this.nama_lengkap;
      if (this.desa_id == null) {
        var desa_id = "";
        //   console.log('tes');
      } else {
        var desa_id = this.desa_id;
      }
      //   console.log(desa_id);
      var alamat = this.alamat;
      var nohp = this.nohp;
      var nohpkuasa = this.nohpkuasa;
      var picture = this.picture;
      var emailUSer = this.emailUser;
      var password = this.password;
      const dataku = new FormData();
      var self = this;
      dataku.append("jenis_identitas", jenis_identitas);
      dataku.append("noktp", noktp);
      dataku.append("nama_lengkap", nama_lengkap);
      dataku.append("desa_id", desa_id);
      dataku.append("alamat", alamat);
      dataku.append("nohp", nohp);
      dataku.append("nohpkuasa", nohpkuasa);
      dataku.append("picture", picture);
      dataku.append("email", emailUSer);
      dataku.append("password", password);
      $.ajax({
        url: self.$config.url_api + "setting/users/updateBiodata",
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
        success: function (response) {
          if (response.status == "ok") {
            self.$router.go();
          } else {
            self.error = true;
            self.items = response.errors;
          }
        },
      });
    },
  },
};
</script>
