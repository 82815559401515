import Vue from "vue";
import Router from "vue-router";
import AppHeader from "../layout/AppHeader";
import AppFooter from "../layout/AppFooter";
import Components from "../views/Components.vue";
import Landing from "../views/Landing.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Profile from "../views/Profile.vue";
import Telegram from "../views/Telegram.vue";
import Tracking from "../views/Tracking.vue";
import ListIzin from "../views/ListIzin.vue";
import FormIzin from "../views/FormIzin.vue";
import Perpanjangan from "../views/Perpanjangan.vue";
import Revisi from "../views/Revisi.vue";
import LupaPass from "../views/LupaPass.vue";
import KonfirmasiPass from "../views/KonfirmasiPass.vue";
import Permohonan from "../views/Permohonan.vue";
import Pencabutan from "../views/Pencabutan.vue";
import scanner from "../views/scanner.vue";
import HowRev from "../views/HowRev.vue";

Vue.use(Router);

export default new Router({
  // mode: 'history',
  // linkExactActiveClass: "active",
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior() {
      return window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  routes: [
    {
      path: "/",
      name: "landing",
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter
      }
    },
    {
      path: "/cek_pajak",
      name: "cek_pajak",
      redirect: to => {
        // the function receives the target route as the argument
        window.location.href = 'http://cekpajak.pasuruankab.go.id';
      }
    },
    {
      path: "/components",
      name: "components",
      components: {
        header: AppHeader,
        default: Components,
        footer: AppFooter
      }
    },
    {
      path: "/login",
      name: "login",
      components: {
        header: AppHeader,
        default: Login,
        footer: AppFooter
      }
    },
    {
      path: "/listizin",
      name: "List Izin",
      components: {
        header: AppHeader,
        default: ListIzin,
        footer: AppFooter
      }
    },
    {
      path: "/register",
      name: "register",
      components: {
        header: AppHeader,
        default: Register,
        footer: AppFooter
      }
    },
    {
      path: "/profile",
      name: "profile",
      meta: {requiresAuth: true},
      components: {
        header: AppHeader,
        default: Profile,
        footer: AppFooter
      }
    },
    {
      path: "/telegram",
      name: "telegram",
      meta: {requiresAuth: true},
      components: {
        header: AppHeader,
        default: Telegram,
        footer: AppFooter
      }
    },
    {
      path: "/form_izin/:kategoriId",
      name: "formizin",
      meta: {requiresAuth: true},
      components: {
        header: AppHeader,
        default: FormIzin,
        footer: AppFooter
      }
    },
    {
      path: "/perpanjangan/:nomorperizinan",
      name: "Perpanjangan",
      meta: {requiresAuth: true},
      components: {
        header: AppHeader,
        default: Perpanjangan,
        footer: AppFooter
      }
    },
    {
      path: "/permohonansaya",
      name: "permohonansaya",
      meta: {requiresAuth: true},
      components: {
        header: AppHeader,
        default: Permohonan,
        footer: AppFooter
      }
    },
    {
      path: "/pencabutan",
      name: "pencabutan",
      meta: {requiresAuth: true},
      components: {
        header: AppHeader,
        default: Pencabutan,
        footer: AppFooter
      }
    },
    {
      path: "/tracking/:nomorperizinan",
      name: "tracking",
      components: {
        header: AppHeader,
        default: Tracking,
        footer: AppFooter
      }
    },
    {
      path: "/revisi/:id/:nomorperizinan",
      name: "revisi",
      meta: {requiresAuth: true},
      components: {
        header: AppHeader,
        default: Revisi,
        footer: AppFooter
      }
    },
    {
      path: "/lupapass",
      name: "lupapass",
      components: {
        header: AppHeader,
        default: LupaPass,
        footer: AppFooter
      }
    },
    {
      path: "/konfirmasipass/:token",
      name: "konfirmasipass",
      components: {
        header: AppHeader,
        default: KonfirmasiPass,
        footer: AppFooter
      }
    },
    {
      path: "/scanner",
      name: "scanner",
      components: {
        header: AppHeader,
        default: scanner,
        footer: AppFooter
      }
    },
    {
      path: "/howrev",
      name: "howtorevisi",
      meta: {requiresAuth: true},
      components: {
        header: AppHeader,
        default: HowRev,
        footer: AppFooter
      }
    },
  {
    path: "*",
    name: "notfound",
  redirect: "/" }
  ],
  base : process.env.NODE_ENV === 'production' ? '/' : '/',
  // scrollBehavior: to => {
  //   if (to.hash) {
  //     return { selector: to.hash };
  //   } else {
  //     return { x: 0, y: 0 };
  //   }
  // }
});


