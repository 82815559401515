let config = null;

process.env.NODE_ENV === 'production'
    // ? config = {
    //     url_api : `https://latihan.siapmaslahat.pasuruankab.go.id/api/api/`,
    //     base_url: `https://latihan.siapmaslahat.pasuruankab.go.id/`,
    //     images_url: `https://latihan.siapmaslahat.pasuruankab.go.id/api/storage/images/`,
    //     file_url: `https://latihan.siapmaslahat.pasuruankab.go.id/api/storage/uploads/`
    // }
    ? config = {
        url_api : `https://siapmaslahat.pasuruankab.go.id/api/api/`,
        base_url: `https://siapmaslahat.pasuruankab.go.id/`,
        images_url: `https://siapmaslahat.pasuruankab.go.id/api/storage/images/`,
        file_url: `https://siapmaslahat.pasuruankab.go.id/api/storage/uploads/`
    }
    : config = {
        url_api : `http://localhost:8000/api/`,
        base_url: `http://localhost:8080/`,
        images_url: `http://localhost:8000/storage/images/`,
        file_url: `http://localhost:8000/storage/uploads/`,
    }
export default config;
