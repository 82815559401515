<template>
  <div class="profile-page">
    <section class="section section-shaped section-lg my-0">
      <div class="shape shape-style-1 bg-gradient-success"></div>
      <div class="row row-grid justify-content-center">
        <div class="col-lg-8 text-center">
          <h2 class="text-white">Pencabutan Izin</h2>
        </div>
      </div>
    </section>

    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--4000">
          <div>
          <b-row>
            <b-col sm="12" md="6" class="my-1">
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Masukkan text untuk mencari..."
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      :disabled="!filter"
                      @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col sm="12" md="3" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
            <b-col sm="12" md="3" class="my-1">
              <button class="btn btn-primary btn-sm" @click="modalPengajuan">
                <i class="fa fa-plus"></i>
                Ajukan pencabutan
              </button>
            </b-col>
          </b-row>
          </div>
          <!-- Main table element -->
          <b-table
            responsive
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            @filtered="onFiltered"
          >
            <template v-slot:cell(id)="row">
              {{row.item.id}}
              <div v-if="row.item.notif != null">
                <span class="badge badge-primary">Progress Baru!</span>
              </div>
            </template>
            <template v-slot:cell(status)="row">
              <span class='badge' :class="row.item.badge">{{row.item.status}}</span>
            </template>
            <template v-slot:cell(actions)="row">
              <span>
                <b-button
                  size="sm"
                  @click="infoPencabutan(row.item.id_pencabutan)"
                  class="mr-1"
                  variant="info"
                  style="width: 100%; margin-bottom: 10%"
                >
                  Detail
                </b-button>
              </span>
            </template>
          </b-table>

          <!-- Info modal -->
          <b-modal
            ref="info-modal"
            title="DETAIL PENCABUTAN"
            variant="info"
            header-bg-variant="info"
            content-class="border-info"
            ok-only
            ok-variant="info"
            size="lg"
            @hide="resetInfoModal"
          >
            <div v-if="detail.nomorperizinan!=null">
              <b-row>
                <b-col sm="12" md="3" class="mt-1">Nomor Perizinan</b-col>
                <b-col sm="12" md="9" class="mt-1">{{detail.nomorperizinan}}</b-col>
              </b-row>
              <b-row>
                <b-col sm="12" md="3" class="mt-1">Jenis izin</b-col>
                <b-col sm="12" md="9" class="mt-1">{{detail.jenis_izin}}</b-col>
              </b-row>
              <b-row>
                <b-col sm="12" md="3" class="mt-1">Tanggal terbit</b-col>
                <b-col sm="12" md="9" class="mt-1">{{detail.tanggal_terbit}}</b-col>
              </b-row>
              <b-row>
                <b-col sm="12" md="3" class="mt-1">Tanggal permohonan pencabutan</b-col>
                <b-col sm="12" md="9" class="mt-1">{{detail.tanggal_pencabutan}}</b-col>
              </b-row>
              <b-row>
                <b-col sm="12" md="3" class="mt-1">Status pencabutan</b-col>
                <b-col sm="12" md="9" class="mt-1">
                  <table  class="mb-2">
                    <tr v-for="item in detail.status_pencabutan" :key="item.waktu">
                      <td style="font-weight:bold;" v-html="item.waktu"></td>
                      <td v-html="item.text_status"></td>
                    </tr>
                  </table>
                  <b-button variant='warning' v-if="!detail.final" @click="confirmBatalkanPencabutan(detail.id_pencabutan)">Batalkan pencabutan</b-button>
                  <b-button variant='success' v-if="detail.sk_pencabutan" @click="showSKPencabutan(detail.sk_pencabutan)"><i class="fa fa-file"></i> Tampilkan SK Pencabutan</b-button>
                </b-col>
              </b-row>
            </div>
            <div v-else>
              <i class='fa fa-spinner fa-spin'></i> Memuat...
            </div>
          </b-modal>
          
          <b-modal ref="pengajuan-pencabutan" hide-footer title="Pengajuan Pencabutan Izin" size="lg" no-close-on-backdrop>
            <b-form @submit.prevent="pengajuanSubmit" @reset="pengajuanReset" v-if="!loadingform && form_pencabutan.list_izin.length!=0">
              <b-form-group
                label="Izin:"
                label-for="nomorperizinan"
                description="Pilih izin yang akan dicabut"
              >
                <b-form-select
                  id="nomorperizinan"
                  v-model="form_pencabutan.nomorperizinan"
                  :options="form_pencabutan.list_izin"
                  :disabled="form_pencabutan.list_izin.length==0"
                  required
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Alasan Pencabutan:"
                label-for="alasan_pencabutan"
                description="Sebutkan alasan pencabutan perizinan"
              >
                <b-form-textarea
                  id="alasan_pencabutan"
                  v-model="form_pencabutan.alasan_pencabutan"
                  placeholder="Alasan Pencabutan Izin"
                  required
                ></b-form-textarea>
              </b-form-group>
              <button type="submit" class="btn btn-primary pull-right" :disabled="submitting">
                <span v-if="submitting"><i class="fa fa-spinner fa-spin"></i> Menyimpan</span>
                <span v-else>Simpan dan Ajukan</span>
              </button>
            </b-form>
            <div v-else-if="!loadingform">
              Anda belum memiliki izin yang dapat dicabut
            </div>
            <div v-else>
              <i class="fa fa-spinner fa-spin"></i> Memuat data permohonan
            </div>
          </b-modal>
          <b-modal ref="pembatalan" hide-footer title="Pembatalan Pencabutan Izin" size="lg" no-close-on-backdrop @hide="pembatalanReset" :hide-header-close="pembatalan.submitting">
            <b-form @submit.prevent="batalkanPencabutan" @reset="pembatalanReset">
              <b-form-group
                label="Alasan Pembatalan:"
                label-for="alasan_pencabutan"
                description="Sebutkan alasan pembatalan. Permohonan yang dapat dibatalkan hanya permohonan yang belum final."
              >
                <b-form-textarea
                  id="alasan_pencabutan"
                  v-model="pembatalan.alasan"
                  placeholder="Alasan Pencabutan Izin"
                  required
                ></b-form-textarea>
              </b-form-group>
              <button type="submit" class="btn btn-primary pull-right" :disabled="pembatalan.submitting">
                <span v-if="pembatalan.submitting"><i class="fa fa-spinner fa-spin"></i> Menyimpan</span>
                <span v-else>Batalkan pencabutan</span>
              </button>
            </b-form>
          </b-modal>
          <b-modal id="sk-modal" ok-only size="lg" scrollable>
            <embed
              v-if="srcPDF"
              :src="$config.file_url + 'pencabutan/' + srcPDF + '.pdf'"
              style="width: 100%; height: 32rem"
              type="application/pdf"
            />
          </b-modal>
        </card>
      </div>
    </section>
  </div>
</template>
<style>
</style>
<script>
import $ from "jquery";
import * as moment from "moment";
import "moment/locale/id";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlus,
  faPencilAlt,
  faTrash,
  faInfo,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faPlus, faPencilAlt, faTrash, faInfo, faEye);
import axios from "axios";
export default {
  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },
  data() {
    return {
      RestribusiMultiple: [],
      items: [],
      form: [],
      file: [],
      alamatIzin: "",
      buatBaru: "",
      perorangan: "",
      fields: [
        { key: "nomorperizinan", label: "Nomor Perizinan", class: "text-center" },
        { key: "kategori", label: "Kategori izin", class: "text-center" },
        { key: "status", label: "Status", class: "text-center" },
        { key: "actions", label: "Detail" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      perusahaan: {
        nama: null,
        email: null,
        npwp: null,
        // bidang_usaha: null,
        telp: null,
        alamat: null,
      },
      form_pencabutan:{
        list_izin: [],
        nomorperizinan: null,
        alasan_pencabutan: "",
      },
      detail:{
        nomorperizinan:null,
        jenis_izin:null,
        tanggal_terbit:null,
        tanggal_pencabutan:null,
        status_pencabutan:[],
        final:false,
        id_pencabutan:null,
      },
      pembatalan:{
        nomorperizinan:null,
        alasan:null,
        submitting:false,
      },
      submitting: false,
      loadingform: false,
      srcPDF:null,
    };
  },
  mounted() {
    this.loadTabel();
    // Set the initial number of items
  },
  methods: {
    loadTabel(){

      var self = this;

      axios({
        method: "GET",
        url: this.$config.url_api + "pencabutan/permohonan_pengguna",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
      }).then(function (response) {
        if (response.data.status == "ok") {
          var today = new Date();
          today.setHours(0, 0, 0, 0);
          var data = response.data.data.permohonan;
          self.items = [];

          for (let i = 0; i < data.length; i++) {
            var status = false;
            var varDate = new Date(data[i].created_at);
            if (varDate > today) {
              status = true;
            }

            let badge = 'badge-info';
            if(data[i].current_status.status == 'tolak'){
              badge = 'badge-danger';
            }else if(data[i].current_status.status == 'batal'){
              badge = 'badge-warning';
            }else if(data[i].current_status.status == 'final'){
              badge = 'badge-success';
            }

            self.items.push({
              id_pencabutan: data[i].id_pencabutan,
              nomorperizinan: data[i].nomorperizinan,
              kategori: data[i].kategori,
              tanggal_pengajuan: data[i].tanggal_pengajuan,
              status: data[i].current_status.status,
              actions: data[i].alasan_pencabutan,
              badge: badge,
            });
          }
          self.totalRows = data.length;
        }
      });
    },
    noFormperpanjangan() {
      alert(
        "Mohon maaf, untuk saat ini anda belum bisa melakukan perpanjangan online."
      );
    },
    modalPengajuan(){
      this.$refs['pengajuan-pencabutan'].show();
      this.loadingform = true;
      const self = this;
      axios({
        method: "GET",
        url:
          this.$config.url_api +
          "pencabutan/list_izin_siap_cabut",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
      }).then(function (response) {
        self.form_pencabutan.list_izin = response.data;
        self.loadingform = false;
        self.submitting = false;
      });
    },
    pengajuanSubmit(){
      this.submitting = true;
      const self = this;
      
      $.ajax({
        url: this.$config.url_api + "pencabutan/ajukan_pencabutan",
        data: {
          id: this.form_pencabutan.nomorperizinan,
          alasan_pencabutan: this.form_pencabutan.alasan_pencabutan,
        },
        cache: false,
        dataType: "json",
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
        success: function (response) {
          if(response.status == "ok"){
            
            self.$toastr.Add({
              title: "Pengajuan pencabutan", // Toast Title
              msg: "Pengajuan pencabutan berhasil disimpan dan menunggu verifikasi.", // Toast Message
              clickClose: true, // Click Close Disable
              position: "toast-top-right", // Toast Position.
              type: "info", // Toast type,
              // timeout: 0,
            });
            self.$refs['pengajuan-pencabutan'].hide();
            self.loadTabel();

          }else{
            self.$toastr.Add({
              title: "Gagal menyimpan", // Toast Title
              msg: response.message, // Toast Message
              clickClose: true, // Click Close Disable
              position: "toast-top-right", // Toast Position.
              type: "error", // Toast type,
              // timeout: 0,
            });
          }
          self.submitting = false;
        },
        error: function(){
          self.$toastr.Add({
            title: "Gagal menyimpan", // Toast Title
            msg: "Terjadi kesalahan saat menyimpan data", // Toast Message
            clickClose: true, // Click Close Disable
            position: "toast-top-right", // Toast Position.
            type: "error", // Toast type,
            // timeout: 0,
          });
          self.submitting = false;
        }
      });
    },
    pengajuanReset(){
      this.form_pencabutan.nomorperizinan = "";
      this.form_pencabutan.alasan_pencabutan = "";
      this.submitting = false;
    },
    moment: function (date) {
      moment.locale("id");
      return moment(date, "YYYY-MM-DD LTS").format("dddd, LLL");
    },
    momentDate: function (date) {
      moment.locale("id");
      return moment(date, "YYYY-MM-DD LTS").format("dddd, LL");
    },
    infoPencabutan(item) {
      this.$refs['info-modal'].show();
      this.resetInfoModal();
      var self = this;
      axios({
        method: "GET",
        url:
          this.$config.url_api +
          "pencabutan/info/" +
          item,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
      }).then(function (response) {
        if (response.data.status == "ok") {
          var info_pencabutan = response.data.data;
          self.detail.nomorperizinan = info_pencabutan.nomorperizinan;
          self.detail.jenis_izin = info_pencabutan.jenis_izin;
          self.detail.tanggal_terbit = info_pencabutan.tanggal_terbit;
          self.detail.tanggal_pencabutan = info_pencabutan.tanggal_pencabutan;
          self.detail.status_pencabutan = info_pencabutan.status_pencabutan;
          self.detail.final = info_pencabutan.final;
          self.detail.id_pencabutan = info_pencabutan.id_pencabutan;
          self.detail.sk_pencabutan = info_pencabutan.sk_pencabutan;
        }else{
          this.$refs['info-modal'].hide();
          self.$toastr.Add({
            title: "Gagal memuat data", // Toast Title
            msg: response.data.message, // Toast Message
            clickClose: true, // Click Close Disable
            position: "toast-top-right", // Toast Position.
            type: "error", // Toast type,
            // timeout: 0,
          });
        }
      }).catch(e => {
        self.resetInfoModal();
        this.$refs['info-modal'].hide();
        self.$toastr.Add({
          title: "Gagal memuat", // Toast Title
          msg: "Terjadi kesalahan saat memuat data", // Toast Message
          clickClose: true, // Click Close Disable
          position: "toast-top-right", // Toast Position.
          type: "error", // Toast type,
          // timeout: 0,
        });
      });
    },
    resetInfoModal() {
      this.detail.nomorperizinan = null;
      this.detail.jenis_izin = null;
      this.detail.tanggal_terbit = null;
      this.detail.tanggal_pencabutan = null;
      this.detail.status_pencabutan = [];
      this.detail.final = false;
      this.detail.id_pencabutan = null;
    },
    confirmBatalkanPencabutan(id){
      this.pembatalan.nomorperizinan = id;
      this.$refs['pembatalan'].show();
    },
    batalkanPencabutan(id){
      this.pembatalan.submitting = true;
      const self = this;
      
      $.ajax({
        url: this.$config.url_api + "pencabutan/batalkan_pencabutan",
        data: {
          id: this.pembatalan.nomorperizinan,
          keterangan: this.pembatalan.alasan,
        },
        cache: false,
        dataType: "json",
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.$cookies.get("landing"),
        },
        success: function (response) {
          if(response.status == "ok"){
            
            self.$toastr.Add({
              title: "Pembatalan berhasil", // Toast Title
              msg: "Pengajuan pencabutan berhasil dibatalkan.", // Toast Message
              clickClose: true, // Click Close Disable
              position: "toast-top-right", // Toast Position.
              type: "info", // Toast type,
              // timeout: 0,
            });
            self.$refs['pembatalan'].hide();
            self.$refs['info-modal'].hide();
            self.loadTabel();

          }else{
            self.$toastr.Add({
              title: "Gagal menyimpan", // Toast Title
              msg: response.message, // Toast Message
              clickClose: true, // Click Close Disable
              position: "toast-top-right", // Toast Position.
              type: "error", // Toast type,
              // timeout: 0,
            });
          }
          self.pembatalan.submitting = false;
        },
        error: function(){
          self.$toastr.Add({
            title: "Gagal menyimpan", // Toast Title
            msg: "Terjadi kesalahan saat menyimpan data", // Toast Message
            clickClose: true, // Click Close Disable
            position: "toast-top-right", // Toast Position.
            type: "error", // Toast type,
            // timeout: 0,
          });
          self.pembatalan.submitting = false;
        }
      });
    },
    pembatalanReset(id){
      this.pembatalan.id_pencabutan = null;
      this.pembatalan.alasan = null;
      this.pembatalan.submitting = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    showSKPencabutan(nama_file) {
      this.srcPDF = nama_file;
      this.$bvModal.show("sk-modal");
    },
  },
};
</script>