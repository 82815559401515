<template>
  <div class="profile-page">
    <section class="section section-shaped section-lg my-0">
      <div class="shape shape-style-1 bg-gradient-success"></div>
      <div class="row row-grid justify-content-center">
        <div class="col-lg-8 text-center">
          <h3 class="text-white">
            Sambungkan Telegram Anda Untuk Mendapatkan Notifikasi.
          </h3>
        </div>
      </div>
    </section>

    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--4000">
          <b-col lg="12">
            <div class="main-card mb-3 card">
              <div class="card-body">
                <h4>TUTORIAL MENYAMBUNGKAN DENGAN TELEGRAM ANDA :</h4>
                <ol>
                  <li>
                    Klik tautan berikut
                    <a href="http://t.me/siapmaslahatpas_bot"
                      >http://t.me/siapmaslahatpas_bot</a
                    >
                  </li>
                  <li>Klik tomnbol start pada telegram</li>
                  <li>
                    Anda akan mendapat balasan kode OTP berupa 6 digit angka.
                  </li>
                  <li>Masukkan angka tersebut pada field dibawah ini.</li>
                </ol>
                <div>
                  <b-alert
                    :show="dismissCountDown"
                    dismissible
                    :variant="variant"
                    @dismissed="dismissCountdown = 0"
                    @dismiss-count-down="countDownChanged"
                  >
                    {{ alertMessage }}
                  </b-alert>
                </div>
                <hr />
                <div>
                  <b-form-group
                  id="kode"
                  description="Masukkan Kode OTP"
                  label="Kode OTP"
                  label-for="kode"
                  valid-feedback="Tunggu!"
                  :invalid-feedback="feedback"
                  :state="state"
                >
                  <b-form-input
                    id="kode"
                    type="number"
                    v-model="kode"
                    trim
                    @input="invalidFeedback"
                  ></b-form-input>
                </b-form-group>
                </div>
              </div>
            </div>
          </b-col>
        </card>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data: () => ({
    heading: "KONEK DENGAN TELEGRAM ANDA",
    subheading: "Dapatkan notifikasi secara real time ke telegram anda.\n",
    icon: "pe-7s-plug icon-gradient bg-sunny-morning",
    okOnly: true,
    alertMessage: "Harap Pilih salah satu",
    dismissSecs: 5,
    dismissCountDown: 0,
    showDismissibleAlert: false,
    variant: "danger",
    kode: "",
    feedback: "",
  }),
  computed: {
    state() {
      return this.kode.length >= 6;
    },
  },
  methods: {
    invalidFeedback() {
      // console.log(this.kode.length);
      if (this.kode.length >= 0 && this.kode.length < 6) {
        this.feedback = "Maksimal masukkan 6 angka";
      } else if (this.kode.length == 6) {
        var self = this;
        axios({
          method: "post",
          url: self.$config.url_api + "telegrambot/verifikasi_token",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
            Authorization: "Bearer " + this.$cookies.get("code"),
          },
          data: {
            kode: this.kode,
          },
        }).then(function (response) {
          // console.log(response.data)
          if (response.data.status == "ok") {
            self.variant = "success";
          } else {
            self.variant = "danger";
          }
          self.alertMessage = response.data.message;
          self.dismissCountDown = self.dismissSecs;
          self.kode = "";
        });
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>